import React from 'react'
import ResourcesLocationLayout from '../../components/resources/ResourcesLayout'


const data = [
    {
        title: 'Overview',
        key: '/os',
        blocks: [
            {
                key: '/os/concept',
                name: "Teddra OS, c'est quoi ?",
                icon: '',
                description: 'Présentation générale de Teddra OS'
            },
            {
                key: '/os/how-to',
                name: 'Comment ca marche ?',
                icon: '',
                description: "Le fonctionnement d'ensemble de teddra OS"
            },
            {
                key: '/os/demo',
                name: 'Reserver une demo',
                icon: 'Demo',
                description: 'A votre disposition pour une démo'
            },
            
        ]
    },
    {
        title: 'Modules',
        key: '/os',
        blocks: [
            {
                key: '/os/dock',
                name: 'Dock',
                icon: '',
                description: 'Le point de départ vers les ressources Teddra OS'
            },
            {
                key: '/os/search',
                name: 'Search',
                icon: '',
                description: `Le "MacOS Spotlight" dans l'univers Teddra OS`
            },
            {
                key: '/os/files',
                name: 'Files',
                icon: '',
                description: 'Le Collective File System '
            },
            {
                key: '/os/desq',
                name: 'Desq',
                icon: '',
                description: 'Le navigateur pensé pour les pros'
            },
            {
                key: '/os/right-page',
                name: 'RightPage',
                icon: '',
                description: `Le "Word" de l'Informatique Collective`
            },
            {
                key: '/os/qomonpoint',
                name: 'Qomonpoint',
                icon: '',
                description: "Un système d'annuaire et de communication à l'échelle collective"
            },
            {
                key: '/os/news',
                name: 'News',
                icon: '',
                description: 'Un système de journal décentralisé er ouvert'
            },
            {
                key: '/os/calendars',
                name: 'Calendars',
                icon: '',
                description: "Un système d'agenda à l'échelle des communautés"
            },
            {
                key: '/os/jobs',
                name: 'Jobs',
                icon: '',
                description: "Un système d'annonces emplois décentralisé"
            },
            {
                key: '/os/immovables',
                name: 'Immovables',
                icon: '',
                description:"Un système d'annonces immo décentralisé"
            },
            {
                key: '/os/knowledge',
                name: 'Knowledge',
                icon: '',
                description: "Le Savoir accessible et organisé à l'échelle collective"
            },
            {
                key: '/os/market',
                name: 'Market',
                icon: '',
                description: 'La gestion de catalogue accessible'
            },
           
        ]
    },
    {
        title: 'Recherche et developpement',
        key: '/os',
        blocks: [
          
            {
                key: '/os/roadmap',
                name: 'Feuille de route',
                icon: 'Timeline',
                description: 'Le plan de R&D à 3 ans'
            },
            {
                key: '/os/infrastructure',
                name: 'Infrastructure',
                icon: '',
                description: "L'infrastructure derrière Teddra OS"
            },
            {
                key: '/os/grid',
                name: 'Grid',
                icon: '',
                description: 'Le back-office et les équipes derrière Teddra OS'
            },
            {
                key: '/os/qorSchema',
                name: 'QorSchema',
                icon: '',
                description: "L'architecture de données au coeur de Teddra OS"
            },
            {
                key: '/os/qorDB',
                name: 'QorDB',
                icon: '',
                description: "L'entrepôt centralisé derrière Teddra OS"
            },
            {
                key: '/os/qordoba',
                name: 'Qorddoba',
                icon: '',
                description: "L'IA derrière le système d'indexation de Teddra OS"
            },
            {
                key: '/os/qorstudoi',
                name: 'QorStudio',
                icon: '',
                description: "L'outil qui ouvre Teddra OS aux développeurs du monde entier"
            },
        ]
    }
   
]
export default function TeddraOsLayout() {
  return (
    <ResourcesLocationLayout data={data} parent={{name: 'Teddra OS', key: '/os'}}>
        <></>
    </ResourcesLocationLayout>
  )
}
