import React from 'react';
import ReactDOM, { createRoot } from 'react-dom/client';
import 'antd/dist/antd.css';
import './styles/theme.css';
import './styles/global.css';
import { createBrowserRouter, createRoutesFromElements, Outlet, Route, RouterProvider } from 'react-router-dom';
import GlobalLayout from './pages/Layout';
import HomeLayout from './pages/home/HomeLayout';
import VisionLayout from './pages/vision/Layout';
import BusinessPlanLayout from './pages/businessPlan/Layout';
import TeddraOsLayout from './pages/teddraOs/Layout';
import SolutionsLayout from './pages/solutions/Layout';
import AboutLayout from './pages/about/Layout';
import { AppContextProvider } from './context/appContext';
import DeploymentLayout from './pages/deployment/Layout';
import PrivateRoute from './components/shared/PrivateRoute';
import TeddraOs from './pages/vision/pages/TeddraOs';

import EcosystemLayout from './pages/ecosystem/Layout';
import NoResource from './components/NoResource';

import Vesrions from './pages/businessPlan/pages/versions';
import Why from './pages/home/pages/Why';
import Fullscreen from './components/details/layout/Fullscreen';
import FullscreenWithModal from './components/details/layout/FullscreenWithModal';
import Concept from './pages/teddraOs/pages/Concept';
import Dock from './pages/teddraOs/pages/Dock';
import Files from './pages/teddraOs/pages/Files';
import Welcome from './pages/home/pages/Welcome';
import Search from './pages/teddraOs/pages/Search';
import News from './pages/teddraOs/pages/News';
import Desq from './pages/teddraOs/pages/Desq';
import RightPage from './pages/teddraOs/pages/RightPage';
import Qomonpoint from './pages/teddraOs/pages/Qomonpoint';
import Calendars from './pages/teddraOs/pages/Calendars';
import Jobs from './pages/teddraOs/pages/Jobs';
import Immovables from './pages/teddraOs/pages/Immovables';
import Knowledge from './pages/teddraOs/pages/Knowledge';
import Market from './pages/teddraOs/pages/Market';
import BusinessPlanV1Layout from './pages/businessPlan/V1Layout';
import Login from './pages/login';
import Oppurtunity from './pages/vision/pages/Oppurtunity';
import Solution from './pages/businessPlan/pages/v1/solution';
import Value from './pages/businessPlan/pages/v1/Value';
import Strategy from './pages/businessPlan/pages/v1/strategy';
import MarketBp from './pages/businessPlan/pages/v1/market';
import Growth from './pages/businessPlan/pages/v1/growth';
import Resources from './pages/businessPlan/pages/v1/resources';
import Forecasts from './pages/businessPlan/pages/v1/forecasts';
import Next from './pages/businessPlan/pages/v1/next';
import Organization from './pages/businessPlan/pages/v1/organization';
import FinancialNeeds from './pages/businessPlan/pages/v1/financialNeeds';
import OnePage from './pages/businessPlan/pages/v1/OnePage';
import PitchDeck from './pages/businessPlan/pages/v1/PitchDeck/PitchDeck';
import PitchDeckFullScreen from './pages/businessPlan/pages/v1/PitchDeck/PitchDeckFullScreen';
import Revenue from './pages/businessPlan/pages/v1/Revenue';
import HowTo from './pages/teddraOs/pages/HowTo';
import Values from './pages/about/pages/Values';
import Team from './pages/about/pages/Team';
import Companies from './pages/about/pages/Companies';
import History from './pages/about/pages/History';
import AboutJobs from './pages/about/pages/Jobs';
import Brand from './pages/solutions/pages/Brand';
import Ecommerce from './pages/solutions/pages/ECommerce';
import HigherEducation from './pages/solutions/pages/HigherEductaion';
import ChamberOfCommerce from './pages/solutions/pages/ChamberOfCommerce';
import Association from './pages/solutions/pages/Association';
import Business from './pages/solutions/pages/Business';
import Pro from './pages/solutions/pages/Pro';
import Developers from './pages/ecosystem/pages/Developers';
import WebAgencies from './pages/ecosystem/pages/WebAgencies';
import Publishers from './pages/ecosystem/pages/Publishers';
import DataProviders from './pages/ecosystem/pages/DataProviders';
import InfraProviders from './pages/ecosystem/pages/InfraProviders';
import TeddraAsPlatform from './pages/ecosystem/pages/TeddraAsPlatform';
import TeddraVentures from './pages/ecosystem/pages/TeddraVentures';
import Brands from './pages/deployment/pages/Brands';
import Thematics from './pages/deployment/pages/Thematics';
import Logistic from './pages/deployment/pages/Logistic';
import Health from './pages/deployment/pages/Health';
import Commerce from './pages/deployment/pages/Commerce';
import Industrie from './pages/deployment/pages/Industry';
import Entrepreneuriat from './pages/deployment/pages/Entrepreneuriat';
import Finance from './pages/deployment/pages/Finance';
import RegionalEconomy from './pages/deployment/pages/RegionalEconomy';
import IT from './pages/deployment/pages/It';
import DataBootstrap from './pages/deployment/pages/DataBootstrap';
import Grid from './pages/deployment/pages/Grid';
import HigherEducationDep from './pages/deployment/pages/HigherEducation';
import Vision from './pages/vision/pages/Vision';
import Roadmap from './pages/teddraOs/pages/Roadmap';
import TeddraFiles from './pages/businessPlan/pages/v1/TeddraFiles';
import Problem from './pages/businessPlan/pages/v1/Problem';



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const router = createBrowserRouter(
  createRoutesFromElements(
    <>
    
    <Route 
      element={<AppContextProvider><Outlet/></AppContextProvider>}
    >
      <Route element={<GlobalLayout/>}>
        <Route element={<Login/>} path='/login'/>
        <Route element={<PrivateRoute/>}>
          <Route element={<HomeLayout/>}>
            <Route element={<Welcome/>} path='/'/>
            <Route element={<Why/>} path='/home/about'/>


          </Route>
          {/*******************VISION****************** */}
          <Route element={<VisionLayout/>}>
            <Route element={<NoResource/>} path='/vision'/>
            <Route element={<Oppurtunity/>} path='/vision/opportunity'/>
            <Route element={<TeddraOs/>} path='/vision/teddraos'/>
            <Route element={<Vision/>} path='/vision/vision'/>

            <Route element={<></>} path='/vision/*'/>
          </Route>
          {/*******************BP****************** */}
          <Route element={<BusinessPlanLayout/>}>
            <Route element={<NoResource/>} path='/bp'/>
            <Route element={<Vesrions/>} path='/bp/versions'/>
            
            <Route element={<></>} path='/bp/*'/>

          </Route>
          {/*******************BP V1****************** */}
          <Route element={<BusinessPlanV1Layout/>}>
            <Route element={<NoResource/>} path='/bpv1'/>
            <Route element={<Solution/>} path='/bpv1/solution'/>
            <Route element={<Value/>} path='/bpv1/value-proposition'/>
            <Route element={<Revenue/>} path='/bpv1/revenue'/>
            <Route element={<MarketBp/>} path='/bpv1/market'/>
            <Route element={<Strategy/>} path='/bpv1/got-to-market'/>
            <Route element={<Growth/>} path='/bpv1/growth-plan'/>
            <Route element={<Resources/>} path='/bpv1/resources'/>
            <Route element={<Forecasts/>} path='/bpv1/forecasts'/>
            <Route element={<Next/>} path='/bpv1/next'/>
            <Route element={<Organization/>} path='/bpv1/organization'/>
            <Route element={<FinancialNeeds/>} path='/bpv1/financial-needs'/>
            <Route element={<TeddraFiles/>} path='/bpv1/files'/>
            <Route element={<OnePage/>} path='/bpv1/one-page'/>
            <Route element={<Problem/>} path='/bpv1/problem'/>

            <Route element={<PitchDeck/>}>
              <Route element={<></>} path='/bpv1/slides'/>
            </Route>
            <Route element={<></>} path='/bpv1/*'/>
          </Route>
          {/*******************TEDDRA OS****************** */}
          <Route element={<TeddraOsLayout/>}>
            <Route element={<NoResource/>} path='/os'/>
            <Route element={<Concept/>} path='/os/concept'/>
            <Route element={<Dock/>} path='/os/dock'/>
            <Route element={<Files/>} path='/os/files'/>
            <Route element={<Search/>} path='/os/search'/>
            <Route element={<News/>} path='/os/news'/>
            <Route element={<Desq/>} path='/os/desq'/>
            <Route element={<RightPage/>} path='/os/right-page'/>
            <Route element={<Qomonpoint/>} path='/os/qomonpoint'/>
            <Route element={<Calendars/>} path='/os/calendars'/>
            <Route element={<Jobs/>} path='/os/jobs'/>
            <Route element={<Immovables/>} path='/os/immovables'/>
            <Route element={<Knowledge/>} path='/os/knowledge'/>
            <Route element={<Market/>} path='/os/market'/>
            <Route element={<HowTo/>} path='/os/how-to'/>
            <Route element={<Roadmap/>} path='/os/roadmap'/>


            <Route element={<></>} path='/os/*'/>
          </Route>
          {/*******************DEPLOYMENT****************** */}
          <Route element={<DeploymentLayout/>}>
            <Route element={<NoResource/>} path='/deployment'/>
            <Route element={<Grid/>} path='/deployment/grid'/>
            <Route element={<DataBootstrap/>} path='/deployment/data-bootstrap'/>
            <Route element={<IT/>} path='/deployment/it'/>
            <Route element={<RegionalEconomy/>} path='/deployment/re'/>
            <Route element={<HigherEducationDep/>} path='/deployment/higher-education'/>
            <Route element={<Finance/>} path='/deployment/finance'/>
            <Route element={<Entrepreneuriat/>} path='/deployment/entrepreneuriat'/>
            <Route element={<Industrie/>} path='/deployment/industry'/>
            <Route element={<Commerce/>} path='/deployment/commerce'/>
            <Route element={<Health/>} path='/deployment/health'/>
            <Route element={<Logistic/>} path='/deployment/logistic'/>
            <Route element={<Thematics/>} path='/deployment/thematics'/>
            <Route element={<Brands/>} path='/deployment/brands'/>



            <Route element={<></>} path='/deployment/*'/>
          </Route>
          {/*******************ECOSYSTEM****************** */}
          <Route element={<EcosystemLayout/>}>
            <Route element={<NoResource/>} path='/ecosystem'/>
            <Route element={<TeddraAsPlatform/>} path='/ecosystem/teddra-as-platform'/>
            <Route element={<TeddraVentures/>} path='/ecosystem/teddra-ventures'/>
            <Route element={<InfraProviders/>} path='/ecosystem/infra-providers'/>
            <Route element={<DataProviders/>} path='/ecosystem/data-providers'/>
            <Route element={<Publishers/>} path='/ecosystem/publishers'/>
            <Route element={<WebAgencies/>} path='/ecosystem/web-agencies'/>
            <Route element={<Developers/>} path='/ecosystem/developers'/>

            <Route element={<></>} path='/ecosystem/*'/>
          </Route>
          {/*******************SOLUTION****************** */}
          <Route element={<SolutionsLayout/>}>
            <Route element={<NoResource/>} path='/solutions'/>
            <Route element={<Pro/>} path='/solutions/pro'/>
            <Route element={<Business/>} path='/solutions/business'/>
            <Route element={<Association/>} path='/solutions/association'/>
            <Route element={<ChamberOfCommerce/>} path='/solutions/chambers-of-commerce'/>
            <Route element={<HigherEducation/>} path='/solutions/higher-education'/>
            <Route element={<Ecommerce/>} path='/solutions/ecommerce'/>
            <Route element={<Brand/>} path='/solutions/brand'/>


            <Route element={<></>} path='/solutions/*'/>
          </Route>
          {/*******************ABOUT****************** */}
          <Route element={<AboutLayout/>}>
            <Route element={<NoResource/>} path='/about'/>
            <Route element={<History/>} path='/about/history'/>
            <Route element={<Companies/>} path='/about/companies'/>
            <Route element={<Team/>} path='/about/team'/>
            <Route element={<Values/>} path='/about/values'/>
            <Route element={<AboutJobs/>} path='/about/jobs'/>



            <Route element={<></>} path='/about/*'/>
          </Route>
        
        </Route>
      </Route>
      <Route element={<Fullscreen navigationBack='/bpv1/slides'><PitchDeckFullScreen fullscreen/></Fullscreen>} path='/bpv1/slides/fullscreen2'/>

    </Route>
    
    </>

  )
);
root.render(
  <RouterProvider router={router} />
);

