import React from 'react'
import DetailsLayout from '../../../components/details/layout'
import PageContainer from '../../../components/details/layout/PageContainer'
import { maxLgWinWidth } from '../../../utils/utils'
import { useAppContext } from '../../../context/appContext'
import Block1 from '../../../components/details/blocks/block1'
import PageInner from '../../../components/details/layout/PageInner'
import Block2 from '../../../components/details/blocks/block2/Block2'
import Figure from '../../../components/details/blocks/figure/Figure'

export default function HowTo() {
  const { winSize } = useAppContext()
  return (
    <div className='h-full relative'>
      <DetailsLayout navigateBack='/os'>
            <>
                <PageContainer isLg={winSize.w > maxLgWinWidth}>
                  <PageInner>
                    <div className="space-between-main-blocks">
                      <Block1
                          title="Comment ca marche ?"
                          text="Le fonctionnement d'ensemble de teddra OS"
                          img=''
                      />
                      <div className="space-between-blocks">
                        <Block2
                            blocks={[
                                {
                                    label: 'Lorem ipsum',
                                    title: 'Lorem ipsum dolor sit',
                                    text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem accusantium non doloribus, aspernatur porro atque'
                                },
                                {
                                    label: 'Lorem ipsum',
                                    title: 'Lorem ipsum dolor sit',
                                    text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quidem accusantium non doloribus, aspernatur porro atque'
                                },
                            ]}
                        />
                        <Figure
                          label='Figure n.'
                          img=''
                        />
                      </div>
                    </div>

                  </PageInner>
                </PageContainer>
                      
                    
                    
                
                </>
        
      </DetailsLayout>

    </div>
  )
}
