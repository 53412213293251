import React from 'react'
import ResourcesLocationLayout from '../../components/resources/ResourcesLayout'


const data = [
    {
        title: 'About',
        key: '/about',
        blocks: [
            {
                key: '/about/history',
                name: 'History',
                icon: 'Timeline',
                description: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis recusandae, beatae placeat sit harum delectus atque enim molestias ullam illum repellendus sequi!'
            },
            {
                key: '/about/companies',
                name: 'Companies',
                icon: '',
                description: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis recusandae, beatae placeat sit harum delectus atque enim molestias ullam illum repellendus sequi!'
            },
            {
                key: '/about/team',
                name: 'Team',
                icon: '',
                description: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis recusandae, beatae placeat sit harum delectus atque enim molestias ullam illum repellendus sequi!'
            },
            {
                key: '/about/values',
                name: 'Values',
                icon: '',
                description: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis recusandae, beatae placeat sit harum delectus atque enim molestias ullam illum repellendus sequi!'
            },
            {
                key: '/about/jobs',
                name: 'Jobs',
                icon: '',
                description: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis recusandae, beatae placeat sit harum delectus atque enim molestias ullam illum repellendus sequi!'
            }
        ]
    }
   
]
export default function AboutLayout() {
  return (
    <ResourcesLocationLayout data={data} parent={{key: '/about', name: 'About'}}>
        <></>
    </ResourcesLocationLayout>
  )
}
