import React from 'react'
import DetailsLayout from '../../../components/details/layout'
import PageContainer from '../../../components/details/layout/PageContainer'
import { maxLgWinWidth } from '../../../utils/utils'
import { useAppContext } from '../../../context/appContext'
import Block1 from '../../../components/details/blocks/block1'
import PageInner from '../../../components/details/layout/PageInner'
import Block2 from '../../../components/details/blocks/block2/Block2'
import TableTwoColumns from '../../../components/details/blocks/tables/TableTwoColumns'
import ModulesPresentationLayout from '../components/ModulesPresentationLayout'

export default function Calendars() {
  const { winSize } = useAppContext()
  return (
    <div className='h-full relative'>
      <DetailsLayout navigateBack='/os'>
            <>
            <ModulesPresentationLayout
                    
                    block1={
                        <Block1
                            title='Calendars'
                            text="Un système d'agenda à l'échelle des communautés"
                            img=''
                        />
                    }
                    block2={
                        <Block2
                                    blocks={[
                                    {
                                        label: 'Description',
                                        title: 'Lorem ipsum dolor sit amet consectetur',
                                        text:' Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium iusto earum quam tempore qui voluptas veniam. Ab, distinctio quisquam quaerat labore odio facilis'
                                    }
                                    ]}
                                
                                />
                    }
                    img=''
                
                />

                <PageContainer isLg={winSize.w > maxLgWinWidth}>
                        <PageInner>
                               <TableTwoColumns
                                    label='Tableau 1 : Presentation de Files'
                                    rows={[
                                        {
                                            columns: [
                                                {
                                                    text: 'Module',
                                                    isTitle:true
                                                },
                                                {
                                                    text: 'Description',
                                                    isTitle:true
                                                },
                                            ],
                                            isColored:false
                                        },
                                        {
                                            columns: [
                                                {
                                                    text: 'Date de sortie',
                                                    isTitle:false
                                                },
                                                {
                                                    text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem distinctio.',
                                                    isTitle:false
                                                },
                                            ],
                                            isColored:true
                                        },
                                        {
                                            columns: [
                                                {
                                                    text: 'Positionnement',
                                                    isTitle:false
                                                },
                                                {
                                                    text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem distinctio.',
                                                    isTitle:false
                                                },
                                            ],
                                            isColored:false
                                        },
                                        {
                                            columns: [
                                                {
                                                    text: 'Marché ciblé',
                                                    isTitle:false
                                                },
                                                {
                                                    text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem distinctio.',
                                                    isTitle:false
                                                },
                                            ],
                                            isColored:true
                                        },
                                        {
                                            columns: [
                                                {
                                                    text: 'Concurrence',
                                                    isTitle:false
                                                },
                                                {
                                                    text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem distinctio.',
                                                    isTitle:false
                                                },
                                            ],
                                            isColored:false
                                        },
                                        {
                                            columns: [
                                                {
                                                    text: 'Différenciation',
                                                    isTitle:false
                                                },
                                                {
                                                    text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem distinctio.',
                                                    isTitle:false
                                                },
                                            ],
                                            isColored:true
                                        },
                                        {
                                            columns: [
                                                {
                                                    text: 'Facteurs clés',
                                                    isTitle:false
                                                },
                                                {
                                                    text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem distinctio.',
                                                    isTitle:false
                                                },
                                            ],
                                            isColored:false
                                        }
                                    ]}
                               
                               />
                         
                        
                        </PageInner>
                    </PageContainer>
                      
                    
                    
                
                </>
        
      </DetailsLayout>

    </div>
  )
}
