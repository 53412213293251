
import { Outlet, useNavigate } from 'react-router-dom'
import DetailsLayout from '../../../../../components/details/layout'

export default function PitchDeck() {
  const navigate = useNavigate()
  return (

    <div className='h-full relative'>
      <DetailsLayout navigateBack='/bpv1'>
            <>
                <div className='bg-container h-full w-full relative'>
                    <div className='bg-white w-[200px] h-[145px] border  absolute top-[40px] left-[0px]'></div>
                    <div className='bg-white w-[200px] h-[145px] border absolute top-[43px] left-[3px]'></div>
                    <div className='bg-white w-[200px] h-[145px] border absolute top-[46px] left-[6px]'></div>
                    <div className='bg-white w-[200px] h-[145px] border absolute top-[49px] left-[9px]'>
                        <div className="w-full h-full flex items-center justify-center cursor-pointer" onClick={() => navigate('/bpv1/slides/fullscreen2')}>
                            <img src='/images/logo.png' className='w-[50px] h-auto'/>
                        </div>
                    </div>


                </div>
                    
                    
            </>

      </DetailsLayout>
               <Outlet/> 
        

    </div>
  )
}
