import React, { useEffect } from 'react'
import ResourcesLocationLayout from '../../components/resources/ResourcesLayout'



const data = [
    {
        title: 'Stratégie',
        key: '/deployment',
        blocks: [
            {
                key: '/deployment/grid',
                name: 'Déploiement en grille',
                icon: '',
                description: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis recusandae, beatae placeat sit harum delectus atque enim molestias ullam illum repellendus sequi!'
            },
            {
              key: '/deployment/data-bootstrap',
              name: 'Data bootstrap',
              icon: '',
              description: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis recusandae, beatae placeat sit harum delectus atque enim molestias ullam illum repellendus sequi!'
          },
          {
            key: '/deployment/d',
            name: 'Calendrier de déploiement',
            icon: '',
            description: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis recusandae, beatae placeat sit harum delectus atque enim molestias ullam illum repellendus sequi!'
        },
        ]
      }
   
]

export default function DeploymentLayout() {

  return (
    <ResourcesLocationLayout data={data} parent={{name: 'Deployment', key: '/deployment'}}>
        <></>
    </ResourcesLocationLayout>
  )
}
