import { Modal } from 'antd';
import React, { useEffect, useState } from 'react'
import Icon from '../../icons/Icon';
import { useNavigate } from 'react-router-dom';

export default function Fullscreen({children, navigationBack}: {children:JSX.Element, navigationBack:string}) {
    const navigate = useNavigate()
   
  return (
  
        <div className='teddra-rounded h-full flex flex-col'>
            <div className="bar-h bg-skin-fill-inverted text-skin-inverted px-4 flex items-center justify-between">
                <div className=" flex items-center space-x-2">
                    <Icon name='TeddraLogo' className='icon-sm'/>
                    <p className="font-bold">teddra</p>

                </div>
                <button className='cursor-pointer no-dragable hover:bg-teddra-red hover:text-white hover:fill-white w-[24px] h-[24px] border-transparent flex justify-center items-center rounded disabled:opacity-[0.2]'  onClick={() => navigate(navigationBack)}>
                    <Icon name='Close' className='icon-sm ' />
                </button>
            </div>
            <div className='bg-white p-10 flex space-x-4 flex-1 w-full'>
                {children}
            </div>

        </div>
  )
}
