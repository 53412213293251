import React from 'react'
import Icon from '../icons/Icon'

type Props = {
    name:string,
    description: string,
    icon:string,
    action: (key:string) => void,
    id:string, 
    active:boolean, 
    disabled?:boolean
}
export default function Resource({ name, description, icon, action, active, id, disabled } : Props) {
  return (
    <div className={`p-3 pr-5 flex w-[258px] h-max space-x-2 ${active ? 'bg-skin-fill-inverted text-skin-inverted' : 'bg-hover-transparent'} rounded  ${disabled ? ' cursor-not-allowed' : 'cursor-pointer'} `} onClick={() => !disabled ? action(id) : {}}>
        {/* <div className={`w-[70px] h-[70px] relative ${ !icon && 'bg-skin-fill-muted'}`}>
          <Icon className='w-full h-full' name='Document'/>
          

          <div className='absolute flex justify-center items-center  top-0 left-0 w-full h-full'>
            <Icon className='w-[30px] h-[30px]' name={icon}/>

          </div>
        </div> */}
        <div className={`w-[52px] h-[60px] flex justify-end items-start relative  ${disabled && 'opacity-15'}`}>
          <Icon className='w-[52px] h-[60px]' name={icon ? icon : 'Document'}/>
          
        </div>
        <div className='flex-1'>
            <p className="font-semibold">{name}</p>
            <p className={`ellipsis-2 font-light ${ active ? 'text-white' : ' text-gray-500'}`}>{description}</p>
        </div>
    </div>
  )
}
