import { Form, Modal, Spin } from 'antd';
import React, { useEffect, useState } from 'react'
import Icon from '../../components/icons/Icon';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from "@firebase/auth";
import { auth } from '../../utils/firebase';
import Block1 from '../../components/details/blocks/block1';

export default function Login() {
    const [open, setopen] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState<any>(null);
    const [Status, setStatus] = useState(false);
    const [loading, setloading] = useState(false);
    const navigate = useNavigate()
    const submitForm = (event:any) => {
        event.preventDefault();
        setloading(true)
        setErrors(null)
        signInWithEmailAndPassword(auth , email, password).then( user => {
          
            navigate('/')

         
        }).catch(err => setErrors('Invalid credentials')).finally(() => setloading(false))
      };
    useEffect(() => {
      setopen(true)
    }, [])
    
  return (
    <Modal
        visible={open}
        footer={null}
        closable={false}
        width={1000}
    
    >
        {/* <div className='rounded-lg h-[580px] flex flex-col'>
            <div className="flex items-center justify-between  w-full py-10 px-[130px] h-[350px] relative">
                <div className="w-[330px] mr-[123px]">
                        <p className="font-bold text-[32px]">Teddra Files</p>
                        <p className="text-[23px] leading-[35px]">
                            Explorez le web et localisez l'information avec une précision et une pertinence nouvelles.
                        </p>
                        <p className='text-[23px] leading-[35px]'>Lancement: Janvier 2025</p>
                
                </div>
                <div className="w-full absolute flex justify-end pr-16 top-[-34px] right-[5px]">
                    <div className='w-[390px] '>
                        <img src='/images/logo2.png' className='w-full'/>
                    </div>
                    
                </div>
            </div>
            <div className='py-10 px-[130px] flex space-x-4 flex-1 w-full rounded-b-[8px]'>
                <div className="flex justify-between space-x-9 w-full">
                    <div className='w-[330px]'>
                        <p className="text-[23px] leading-[35px]">
                            Partenaires, connectez-vous avec les codes que vous avez reçus.
                        </p>
                    </div>
                    <div className='max-w-[290px] w-full'>
                        <Form className="space-y-3 flex-1">
                            <Form.Item
                                validateStatus={errors ? "error" : ''}
                                help={errors}
                                className="group"
                                style={{ marginBottom: "0" }}
                                required
                            >
                                <input
                                type="email"
                                placeholder="Email"
                                className={`rounded-lg py-2 px-3 block w-full h-[50px] text-[18px] placeholder:text-[18px]`}
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                    setErrors(null);
                                }}
                                required
                                />
                            </Form.Item>
                            <Form.Item
                                validateStatus={errors ? "error" : ''}
                                help={errors}
                                className="group"
                                style={{ marginBottom: "0" }}
                                required
                            >
                                <input
                                type="password"
                                placeholder="Password"
                                className="rounded-lg py-2 px-3 block w-full h-[50px] text-[18px] placeholder:text-[18px]"
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                    setErrors(null);
                                }}
                                required
                                />
                            </Form.Item>

                            <Spin spinning={loading}>
                                <button
                                className="px-7 bg-skin-fill-inverted rounded-lg py-1.5 w-max block ml-auto disabled:text-teddra-gray-200 h-[50px] text-[18px] text-white"
                                type="submit"
                                disabled={loading}
                                onClick={submitForm}
                                >
                                Envoyer
                                </button>
                            </Spin>
                        </Form>

                    </div>
                </div>
            </div>

        </div> */}

        <div className='rounded-[25px] h-[580px] flex flex-col bg-skin-fill-inverted text-skin-inverted'>
            <div className="flex items-center justify-between  w-full py-10 px-[130px] h-[350px] relative">
                <div className="w-[330px] mr-[123px]">
                        <p className="font-bold text-[32px]">Teddra Files</p>
                        <p className="text-[23px] leading-[35px]">
                            Explorez le web et localisez l'information avec une précision et une pertinence nouvelles.
                        </p>
                        <p className='text-[23px] leading-[35px]'>Lancement: Janvier 2025</p>
                
                </div>
                <div className="w-full absolute flex justify-end pr-16 top-[7px] right-[83px]">
                    <div className='w-[342px] '>
                        <img src='/images/logo-white.png' className='w-full'/>
                    </div>
                    
                </div>
            </div>
            <div className='py-10 px-[130px] flex space-x-4 flex-1 w-full '>
                <div className="flex justify-between space-x-9 w-full">
                    <div className='w-[330px]'>
                        <p className="text-[23px] leading-[35px]">
                            Partenaires, connectez-vous avec les codes que vous avez reçus.
                        </p>
                    </div>
                    <div className='max-w-[290px] w-full'>
                        <Form className="space-y-3 flex-1">
                            <Form.Item
                                validateStatus={errors ? "error" : ''}
                                help={errors}
                                className="group"
                                style={{ marginBottom: "0" }}
                                required
                            >
                                <input
                                type="email"
                                placeholder="Email"
                                className={`rounded-lg py-2 px-3 block w-full h-[50px] text-[18px] placeholder:text-[18px]`}
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                    setErrors(null);
                                }}
                                required
                                />
                            </Form.Item>
                            <Form.Item
                                validateStatus={errors ? "error" : ''}
                                help={errors}
                                className="group"
                                style={{ marginBottom: "0" }}
                                required
                            >
                                <input
                                type="password"
                                placeholder="Password"
                                className="rounded-lg py-2 px-3 block w-full h-[50px] text-[18px] placeholder:text-[18px]"
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                    setErrors(null);
                                }}
                                required
                                />
                            </Form.Item>

                            <Spin spinning={loading}>
                                <button
                                className="px-7 bg-white rounded-lg py-1.5 w-max block ml-auto disabled:text-teddra-gray-200 h-[50px] text-[18px] "
                                type="submit"
                                disabled={loading}
                                onClick={submitForm}
                                >
                                Envoyer
                                </button>
                            </Spin>
                        </Form>

                    </div>
                </div>
            </div>

        </div>
     
         {/* <div className='rounded-lg h-[560px] flex flex-col'>
            <div className="flex items-center justify-between  w-full py-10 px-16 h-[350px] relative">
                <div className='w-[278px]'>
                    <Icon name='Globe' className='w-full '/>
                </div>
                <div className="flex-1 ml-[123px]">
                        <p className="font-bold text-[27px]">Connectez-vous</p>
                        <p className="text-[20px]">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus.
                        </p>
                
                </div>
            </div>
            <div className='bg-skin-fill-inverted text-skin-inverted py-10 px-16 flex space-x-4 flex-1 w-full'>
                <div className="flex justify-between w-full">
                    <div className='w-1/2'>
                        <p className="font-bold text-[20px]">Connectez-vous</p>
                        <p className="text-[16px]">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus.
                        </p>
                    </div>
                    <div className='max-w-[290px] w-full'>
                        <Form className="space-y-3 flex-1">
                            <Form.Item
                                validateStatus={errors ? "error" : ''}
                                help={errors}
                                className="group"
                                style={{ marginBottom: "0" }}
                                required
                            >
                                <input
                                type="email"
                                placeholder="Email"
                                className={`rounded-lg py-2 px-3 block w-full bar-h text-[18px] placeholder:text-[18px]`}
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                    setErrors(null);
                                }}
                                required
                                />
                            </Form.Item>
                            <Form.Item
                                validateStatus={errors ? "error" : ''}
                                help={errors}
                                className="group"
                                style={{ marginBottom: "0" }}
                                required
                            >
                                <input
                                type="password"
                                placeholder="Password"
                                className="rounded-lg py-2 px-3 block w-full bar-h text-[18px] placeholder:text-[18px]"
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                    setErrors(null);
                                }}
                                required
                                />
                            </Form.Item>

                            <Spin spinning={loading}>
                                <button
                                className="px-4 rounded-lg py-1.5 w-max block ml-auto disabled:text-teddra-gray-200 bar-h text-[18px] text-white"
                                style={{background:'rgb(23, 126, 222)'}}
                                type="submit"
                                disabled={loading}
                                onClick={submitForm}
                                >
                                Envoyer
                                </button>
                            </Spin>
                        </Form>

                    </div>
                </div>
            </div>

        </div> */}
    </Modal>
  )
}
