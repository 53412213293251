import React from "react";

import {
    TeddraLogo,
    Document,
    Close,
    Reduce,
    Maximize,
    Info,
    Print,
    Refresh,
    Opportunity,
    Bp,
    Deck,
    Demo,
    Home,
    OneMinute,
    OnePage,
    Slides,
    Important,
    BpVersions,
    Deployment,
    Solution,
    About,
    Fullscreen,
    Ecosystem,
    Calendar,
    Desq,
    Jobs,
    Market,
    News,
    Knowledge,
    Files,
    Immo,
    Dock,
    Search,
    RightPage,
    Qomonpoint,
    Globe,
    TeddraLogoColor,
    Vision,
    Timeline,
    Os,
    RoadmapSm
} from "./index";
import { IconOptions } from "../../types";
type ComponentOptions = {
  [key: string]: React.ElementType
}
const Components : ComponentOptions = {
    TeddraLogo,
    Document,
    Close,
    Reduce,
    Maximize,
    Info,
    Print,
    Refresh,
    Opportunity,
    Bp,
    Deck,
    Demo,
    Home,
    OneMinute,
    OnePage,
    Slides,
    Important,
    BpVersions,
    Deployment,
    Solution,
    About,Fullscreen,Ecosystem,
    Calendar,
    Desq,
    Jobs,
    Market,
    News,
    Knowledge,
    Files,
    Immo,
    Dock,
    Search,
    RightPage,
    Qomonpoint,
    Globe,
    TeddraLogoColor,
    Vision,
    Timeline,
    Os,
    RoadmapSm

};

export default function Icon({ name, className, style } : IconOptions) {
  if (typeof (Components as any)[name ? name : ''] != "undefined") {
    return React.createElement((Components as any)[name ? name : ''], {
      className: className,
      style: style?style:{}
    });
  }

  return <div></div>;
}
