import React from 'react'
import DetailsLayout from '../../../../components/details/layout'
import PageContainer from '../../../../components/details/layout/PageContainer'
import { maxLgWinWidth } from '../../../../utils/utils'
import { useAppContext } from '../../../../context/appContext'
import Block1 from '../../../../components/details/blocks/block1'
import PageInner from '../../../../components/details/layout/PageInner'

export default function OnePage() {
  const { winSize } = useAppContext()
  return (
    <div className='h-full relative'>
      <DetailsLayout navigateBack='/bpv1'>
            <>
                <PageContainer isLg={winSize.w > maxLgWinWidth}>
                  <PageInner>
                    <div className="space-between-main-blocks">
                      <Block1
                          title='Teaser'
                          text='Deux pages de résumé'
                          img=''
                      />
                      <div className="space-between-blocks"></div>
                    </div>

                  </PageInner>
                </PageContainer>
                      
                    
                    
                
                </>
        
      </DetailsLayout>

    </div>
  )
}
