import React from 'react'
import TextBlock2 from './TextBlock2'

type Props = {
    blocks:{
        label:string,
        title:string,
        text:string
    }[]
}
export default function Block2({ blocks } : Props) {
  return (
    <div className="grid gap-16  grid-cols-2   ">
        {blocks.map((block, i) => (
            <TextBlock2 key={i} title={block.title} label={block.label} text={block.text} />
        ))}
    </div>
  )
}
