import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/solid'
import { Tree } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ReactSVG } from 'react-svg'
import OneLine from '../shared/object/oneLine'
import Icon from '../icons/Icon'

type treeProps = {
    iconType:string, iconUrl:string, key:string, title:string, disabled?:boolean
    children?: {iconType:string, iconUrl:string, key:string, title:string,  disabled?:boolean}[]
}
const treeData:treeProps[] = [
    {
        title: 'Home',
        iconType:'icon',
        iconUrl: 'Home',
        key: '/'
    },
    {
        title: 'Vision',
        iconType:'icon',
        iconUrl: 'Vision',
        key: '/vision'
    },
    {
        title: 'Business plan',
        iconType:'icon',
        iconUrl: 'Bp',
        key: '/bp',
        children: [
            {
                title: 'Business plan V1',
                iconType:'icon',
                iconUrl: 'Bp',
                key: '/bpv1',
            },
        
            {
                title: 'Business plan V2',
                iconType:'icon',
                iconUrl: 'Bp',
                key: '/bp/v2',
                disabled:true
            },
            {
                title: 'Business plan V3',
                iconType:'icon',
                iconUrl: 'Bp',
                key: '/bp/v1',
                disabled:true
            },
            {
                title: 'Business plan V4',
                iconType:'icon',
                iconUrl: 'Bp',
                key: '/bp/v4',
                disabled:true
            },
            {
                title: 'Business plan V5',
                iconType:'icon',
                iconUrl: 'Bp',
                key: '/bp/v5',
                disabled:true
            },
            {
                title: 'Business plan V6',
                iconType:'icon',
                iconUrl: 'Bp',
                key: '/bp/v6',
                disabled:true
            },
            {
                title: 'Business plan V7',
                iconType:'icon',
                iconUrl: 'Bp',
                key: '/bp/v7',
                disabled:true
            },
            {
                title: 'Business plan V8',
                iconType:'icon',
                iconUrl: 'Bp',
                key: '/bp/v8',
                disabled:true
            },
            {
                title: 'Business plan V9',
                iconType:'icon',
                iconUrl: 'Bp',
                key: '/bp/v9',
                disabled:true
            },
        ]
    },
    {
        title: 'Teddra OS',
        iconType:'icon',
        iconUrl: 'Os',
        key: '/os'
    },
    {
        title: 'Déploiement',
        iconType:'icon',
        iconUrl: 'Deployment',
        key: '/deployment'
    },
    {
        title: 'Ecosystem',
        iconType:'icon',
        iconUrl: 'Ecosystem',
        key: '/ecosystem'
    },
    {
        title: 'Solutions',
        iconType:'icon',
        iconUrl: 'Solution',
        key: '/solutions'
    },
    {
        title: 'About',
        iconType:'icon',
        iconUrl: 'About',
        key: '/about'
    },
]

export default function Sidebar() {
    const navigate = useNavigate()
    const location = useLocation()
    const [selectedKey, setselectedKey] = useState('/');

    useEffect(() => {
        
            const path = `/${location.pathname.split('/')?.[1]}`
            if(location.pathname.includes('home')){
                setselectedKey('/')
            }else{
                setselectedKey(path)

            }
    }, [location.pathname]);

    const handleLocationSelection = (node:any) => {
        navigate(node.key)
    }
   
      
  return (
        <Tree
            expandAction={'doubleClick'}
            selectedKeys={[selectedKey]}
            treeData={treeData}
            defaultExpandAll
            className='sidebar bg-transparent pt-[18px] w-[97%] h-full pl-[8px]'
         
            switcherIcon={(props : any) =>
                props.expanded ? (
                    <ChevronDownIcon className='w-3.5 h-3.5' />
                ) : (
                    <ChevronRightIcon className='w-3.5 h-3.5' />
                )
            }
            
            titleRender={(location:  {iconType:string, iconUrl:string, key:string, title:string}) => (
                
                    <OneLine
                        draggable
                        active={selectedKey===location.key}
                        dropdownProps={null
                         
                        }
                        className='!p-0 !bg-transparent transition-none !border-none'
                        
                    >
                        <div className='flex justify-between items-center w-full'>
                           
                            <div className={`flex space-x-2.5 items-center  ` }>
                                

                                        <div className='relative w-[20px] h-[20px]'>
                                              {location.iconType === 'icon' ? (
                                                <Icon name={location.iconUrl} className='w-full h-full'/>
                                              ) : (
                                                <ReactSVG 
                                                src={location.iconUrl} 
                                                className='w-full h-full'
                                                beforeInjection={(svg) => {
                                                    svg.setAttribute('style', 'width: 100%; height:100%; fill:inherit ')
                                                }}
                                                />

                                              )}
                                        </div>
                                
                           
                                <p className={`
                              

                                  truncate`}>{location.title}</p>

                            </div>
                         
                        </div>
                        

                    </OneLine>
                

            )}
            onSelect={(node,e) => {
                handleLocationSelection(e.node)
            }}
        />
      
  )
}
