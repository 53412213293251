import React from 'react'
import OuterContainer from './OuterContainer'

export default function DetailsLayoutLg({children, fullWidth}: {children:JSX.Element, fullWidth?:boolean}) {
  return (
  <>
    {fullWidth ? (
      children
    ) : (
      <OuterContainer size='lg' >
        {children}
      </OuterContainer>
    )}
  
  </>
     
  )
}
