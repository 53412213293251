import React from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import Icon from '../components/icons/Icon'
import { Breadcrumb } from '../components/shared/breadcrumb'
import WithDivider from '../components/shared/WithDivider'
import Sidebar from '../components/sidebar'
import { useAppContext } from '../context/appContext'
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/outline'
import TopbarIcons from '../components/shared/bars/TopbarIcons'
import useWindowSize from '../hooks/useWindowSize'
import ResourcesBar from '../components/shared/bars/resourcesBar'

export default function GlobalLayout() {
    const {breadcrumb} = useAppContext()
    useWindowSize()
    const navigate = useNavigate()
  return (
    <div>
        <div className="bar-h bg-main-window flex px-7 items-center text-skin-inverted justify-between">
            <div className="flex space-x-2 items-center">
                <Icon name='TeddraLogo' className='w-[32px] h-[32px]'/>
                <p className='font-bold text-[14px]'>teddra</p>

            </div>
            <TopbarIcons main close={() => {}}/>
        </div>
        <div className="bar-h px-7 flex items-center border-b border-main space-x-2">
            <button className='p-1.5 bg-hover-transparent rounded no-dragable disabled:fill-skin-muted' onClick={() => navigate(-1)} disabled={true}>
                <ArrowLeftIcon className='icon-sm'/>
            </button>
            <button className='p-1.5 bg-hover-transparent rounded no-dragable disabled:fill-skin-muted' disabled={true} onClick={() => navigate(1)}>
                <ArrowRightIcon className='icon-sm'/>
            </button>
            <button className='p-1.5 bg-hover-transparent rounded no-dragable disabled:fill-skin-muted' disabled={true} >
                <Icon name='Refresh' className='icon-sm'/>
            </button>
            <Breadcrumb
                routes={breadcrumb}
                maxElement={3}
            />
        </div>
        <div className="bar-h flex px-7 items-center ">
            <div className='w-[200px]'>
                <WithDivider>
                    <>Volumes</>
                </WithDivider>
            </div>
            <ResourcesBar/>
        </div>
        <div className="flex h-full">
            <div className="w-[200px]">
                <Sidebar/>
            </div>
            
            <Outlet/>
                

        </div>
    </div>
  )
}
