import { CameraIcon } from '@heroicons/react/solid'
import React from 'react'

type Props = {
    label: string,
    img: string,
    imgHeight?:string
}
export default function Figure({ label, img, imgHeight } : Props) {
  return (
    <div className='space-between-same-block '>
        <p className='title-resource'>{label}</p>
        <div className={`mt-2 w-full border border-main flex justify-center items-center bg-skin-inverted rounded ${imgHeight ? imgHeight : 'h-[300px]'}`}>
            <CameraIcon className='w-[50px] h-[50px] text-gray-300'/>
        </div>
    </div>
  )
}
