import React from 'react'
import Row from './Row'
import TableLayout from './TableLayout'

type Props = {
    label: string,
    rows : 
    {
        columns: {
            text: string | JSX.Element,
            isTitle: boolean,
            icon?:string,
            width:string,
            center?:boolean
        }[],
        isColored: boolean,
        center?:boolean

    }[]
}
export default function TableMultiColumns({label, rows} : Props) {
  return (
    <TableLayout label={label}>
        <>
            {rows.map((row,i) => (
                <Row
                    columns={row.columns.map((cl,j) => ({...cl}))}
                    isColored={row.isColored}
                    center={row.center}
                />
            ))}
        
        </>

    </TableLayout>
           

     
  )
}
