import React, { useEffect, useState } from 'react'
import DetailsLayout from '../../../../components/details/layout'
import PageContainer from '../../../../components/details/layout/PageContainer'
import { maxLgWinWidth } from '../../../../utils/utils'
import { useAppContext } from '../../../../context/appContext'
import PageInner from '../../../../components/details/layout/PageInner'
import TableTwoColumns from '../../../../components/details/blocks/tables/TableTwoColumns'
import { useLocation } from 'react-router-dom'
import Block1 from '../../../../components/details/blocks/block1'

export default function TeddraFiles() {
  const { winSize } = useAppContext()
  const location = useLocation()
  const [backUrl, setbackUrl] = useState('/os');


  useEffect(() => {
    
    if(location.pathname.includes('os')){
        setbackUrl('/os')
    }else{
        setbackUrl('/bpv1')
    }
  }, []);
  return (
    <div className='h-full relative'>
      <DetailsLayout navigateBack={backUrl}>
            <>
               
            <PageContainer isLg={winSize.w > maxLgWinWidth}>
                        <PageInner>
                               <div className="space-between-main-blocks">
                                <Block1
                                    title={'Teddra Files'}
                                    text='Le module au coeur du succès de Teddra OS'
                                    img=''
                                />
                               </div>
                         
                        
                        </PageInner>
                    </PageContainer>

           
                      
                    
                    
                
                </>
        
      </DetailsLayout>

    </div>
  )
}
