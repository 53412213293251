
import DetailsLayout from '../../../components/details/layout'
import { useAppContext } from '../../../context/appContext'
import { maxLgWinWidth } from '../../../utils/utils'
import PageContainer from '../../../components/details/layout/PageContainer'
import Block1 from '../../../components/details/blocks/block1'
import PageInner from '../../../components/details/layout/PageInner'


export default function Finance() {
    const { winSize } = useAppContext()
  return (
    <div className='h-full relative'>
     
        <DetailsLayout navigateBack='/deployment'>
            <>
               
            <PageContainer isLg={winSize.w > maxLgWinWidth}>
                        <PageInner>
                            <div className="space-between-main-blocks">
                                <Block1
                                    title='Finance'
                                    text='Lorem ipsum dolor sit, amet consectetur adipisicing elit. Explicabo iure doloribus vero impedit.'
                                    img=''
                                />
                           

                            </div>
                        </PageInner>
                  

                    </PageContainer>
               
                
                   
            
            </>
        </DetailsLayout>
    </div>
  )
}
