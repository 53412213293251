import React from 'react'
import WithDivider from '../../WithDivider'
import Icon from '../../../icons/Icon'
import { useAppContext } from '../../../../context/appContext'

export default function DetailsBar() {
  const { detailsScrollOn } = useAppContext()
  return (
    <div className={`flex  items-center bar-h justify-between w-full ${detailsScrollOn ? 'border-b border-main' : 'border-b border-transparent'}`}>
        
        <WithDivider>
            <>Details</>
        </WithDivider>
        <div className="flex items-center space-x-2">
            <button className='bg-hover-transparent p-1 rounded'>
                <Icon name='Fullscreen' className='icon-sm' />
            </button>
            <button className='bg-hover-transparent p-1 rounded'>
                <Icon name='Print' className='icon-sm' />
            </button>
        </div>

    </div>
  )
}
