import React, { useEffect } from 'react'
import ResourcesLocationLayout from '../../components/resources/ResourcesLayout'



const data = [
 

    {
        title: 'Focus',
        key: '/bpv1',
        
        blocks: [
            {
                key: '/bpv1/files',
                name: 'Teddra Files',
                icon: 'Document',
                description: 'Le module au coeur du succès de Teddra OS'
            },
           {
                key: '/bpv1/one-page',
                name: 'Teaser',
                icon: 'Document',
                description: 'Deux pages de résumé'
            },
            {
                key: '/bpv1/slides',
                name: 'Pitch deck',
                icon: 'Slides',
                description: 'Une préentation en 13 slides'
            },
            {
                key: '/bpv1/pitch',
                name: 'Pitch video',
                icon: 'OneMinute',
                description: "Une vidéo d'une minute 30 pour comprendre le produit",
                disabled:true
            
            },
            
        ]
    },
    {
        title: 'Business Plan v1',
        key: '/bpv1',
        
        blocks: [
            {
                key: '/bpv1/problem',
                name: 'Probléme',
                icon: 'Document',
                description: "Le search aujourd'hui : les limites croissantes des moteurs traditionnels"
            },
         
            {
                key: '/bpv1/solution',
                name: 'Solution',
                icon: 'Document',
                description: 'Teddra Files : le File System du web'
            },
            {
                key: '/bpv1/value-proposition',
                name: 'Proposition de valeur',
                icon: 'Document',
                description: 'Une précision, une pertinence et une productivité nouvelles'
            },
            {
                key: '/bpv1/revenue',
                name: 'Revenus',
                icon: 'Document',
                description: "Les profils et les offres d'abonnements"
            },
            {
                key: '/bpv1/market',
                name: 'Marché',
                icon: 'Document',
                description: 'Le search : un marché qu coeur du web'
            },
            {
                key: '/bpv1/got-to-market',
                name: 'Stratégie',
                icon: 'Document',
                description: 'Le Go to Market'
            },
            {
                key: '/bpv1/growth-plan',
                name: 'Plan de croissance',
                icon: 'Document',
                description: 'Le quadrillage méthodique du web'
            },
            
            {
                key: '/bpv1/resources',
                name: 'Production et ressources',
                icon: 'Document',
                description: "L'infrastructure et la data comme ressources clés"
            },
            {
                key: '/bpv1/organization',
                name: 'Organisation',
                icon: 'Document',
                description: "L'organisation du groupe Teddra"
            },
            {
                key: '/bpv1/next',
                name: 'Prochaines étapes',
                icon: 'Document',
                description: 'Les premiers déploiements clés'
            },
            {
                key: '/bpv1/financial-needs',
                name: 'Besoins financiers',
                icon: 'Document',
                description: 'Les besoins financiers et les investissements à 12 mois'
            },
         
            {
                key: '/bpv1/forecasts',
                name: 'Prévisionnel',
                icon: 'Document',
                description: 'Plan financier à 3 ans (focus sur teddra Files)'
            }
            
        ]
    },
   
]
export default function BusinessPlanV1Layout() {
 

  return (
    <ResourcesLocationLayout data={data} parent={{name: 'Business plan V1', key: '/bpv1'}}>
        <></>
    </ResourcesLocationLayout>
  )
}
