import React from 'react'
import Icon from '../../../components/icons/Icon'
import { CameraIcon } from '@heroicons/react/solid'
import DetailsLayout from '../../../components/details/layout'
import { useAppContext } from '../../../context/appContext'
import { maxLgWinWidth } from '../../../utils/utils'
import PageContainer from '../../../components/details/layout/PageContainer'
import Block1 from '../../../components/details/blocks/block1'
import Block2 from '../../../components/details/blocks/block2/Block2'
import Figure from '../../../components/details/blocks/figure/Figure'
import PageInner from '../../../components/details/layout/PageInner'
export default function Oppurtunity() {
    const { winSize } = useAppContext()
  return (
    <div className='h-full relative'>
     
        <DetailsLayout navigateBack='/vision'>
            <>
             
                    <PageContainer isLg={winSize.w > maxLgWinWidth}>
                        <PageInner>
                            <div className='space-between-main-blocks'>
                                <Block1
                                    title='Opportunité'
                                    text="Le secteur, l'état de l'art, le problème et l'opportunité ciblée"
                                    img='Opportunity'
                                />
                            <div className="space-between-blocks">
                                <Block2
                                        blocks={[
                                            {
                                                label:'Industrie',
                                                title: "L'informatique collective: definition",
                                                text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate nisi ipsa cum odit unde voluptas, error, doloremque porro eius adipisci numquam molestias exercitationem ratione dolorem at accusamus ipsum odio dignissimos.",
                                            },
                                            {
                                                label:'Typologie',
                                                title: "Les resources de l'informatique collective",
                                                text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate nisi ipsa cum odit unde voluptas, error, doloremque porro eius adipisci numquam molestias exercitationem ratione dolorem at accusamus ipsum odio dignissimos.",
                                            },
                                        ]}
                                    />
                                   
                                
                                
                                    <Figure
                                        img=''
                                        label="Figure 1. Les ressources, les acteurs et les usages de l'Informatique Collective"
                                    />

                            </div>

                            
                            </div>
                        </PageInner>
                    </PageContainer>
            
                    <PageContainer isLg={winSize.w > maxLgWinWidth}>
                        <PageInner>
                            <div className="space-between-blocks">
                                <Block2
                                    blocks={[
                                        {
                                            label: "Etat de l'art",
                                            title: "Un site, une platforme",
                                            text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate nisi ipsa cum odit unde voluptas, error, doloremque porro eius adipisci numquam molestias exercitationem ratione dolorem at accusamus ipsum odio dignissimos.'
                                        },
                                        {
                                            label: "Stade de developpement",
                                            title: "L'Informatique Personnelle en 1980",
                                            text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate nisi ipsa cum odit unde voluptas, error, doloremque porro eius adipisci numquam molestias exercitationem ratione dolorem at accusamus ipsum odio dignissimos.'
                                        },
                                    ]}
                                
                                />
                               <Figure
                                    label="Figure 2. L'etat de l'art de l'Informatique Collective"
                                    img=''
                               />
                               
                            </div>
                        </PageInner>
                        

                    </PageContainer>
                    <PageContainer isLg={winSize.w > maxLgWinWidth}>
                        <PageInner>
                            <div className='space-between-blocks'>
                            <Block2
                                blocks={[
                                    {
                                        label: 'Probleme',
                                        text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate nisi ipsa cum odit unde voluptas, error, doloremque porro eius adipisci numquam molestias exercitationem ratione dolorem at accusamus ipsum odio dignissimos.',
                                        title: 'Un site, une platforme'
                                    }
                                ]}
                            />
                               <Figure
                                    label="Figure 3. L'Informatique Collective aujourd'hui: Les problémes pour les utilisateurs et les entreprises"
                                    img=''
                               /> 
                             
                                    
                            </div>
                        </PageInner>
                        

                    </PageContainer>
                    <PageContainer isLg={winSize.w > maxLgWinWidth}>
                        <PageInner>
                            <div className='space-between-blocks'>
                                <Block2
                                    blocks={[
                                        {
                                            label:"Opportunite",
                                            title:"Cree un OS pour l'informatique collective",
                                            text:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate nisi ipsa cum odit unde voluptas, error, doloremque porro eius adipisci numquam molestias exercitationem ratione dolorem at accusamus ipsum odio dignissimos."
                                        },
                                        {
                                            label:"Pourquoi maintenant",
                                            title:"Un alignement des planetes",
                                            text:"Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate nisi ipsa cum odit unde voluptas, error, doloremque porro eius adipisci numquam molestias exercitationem ratione dolorem at accusamus ipsum odio dignissimos."
                                        }
                                    ]}
                                
                                />
                                <Figure
                                    label="Figure 4. L'émergence inéluctable d'un 'Windows' de l'Informatique Collective'"
                                    img=''
                               /> 
                             
                               
                            </div>
                        </PageInner>
                      

                    </PageContainer>
            
            </>
        </DetailsLayout>
    </div>
  )
}
