import React from 'react'

export default function PageContainer({isLg, children} : {isLg:boolean, children:JSX.Element}) {
  return (
    // <div className={`bg-white ${isLg ? 'w-[42vw] h-[63vw]' : 'w-[800px] h-[1200px]'} flex`} style={{boxShadow:'rgba(0, 0, 0, 0.4) 0px 2px 10px'}}>
      <div className={`bg-white ${isLg ? 'w-[42vw] h-[63vw]' : 'w-[800px] h-[1200px]'} flex `} style={{border:'1px solid #c9c9c9'}}>

        
            {children}
            
    </div>
  )
}
