import * as React from "react"
import { SVGProps } from "react"
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="transparent"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={0.5} y={0.5} width={23} height={23} stroke="white" />
    <path
      d="M16.1213 9.87868C17.2929 11.0502 17.2929 12.9497 16.1213 14.1213C14.9497 15.2929 13.0502 15.2929 11.8787 14.1213C10.7071 12.9497 10.7071 11.0502 11.8787 9.87868C13.0502 8.70711 14.9497 8.70711 16.1213 9.87868"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19 17.0001L16.12 14.1201"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21 12V9C21 6.791 19.209 5 17 5H7C4.791 5 3 6.791 3 9V15C3 17.209 4.791 19 7 19H14"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgComponent
