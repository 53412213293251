import React from 'react'
import DetailsLayout from '../../../components/details/layout'
import PageContainer from '../../../components/details/layout/PageContainer'
import { maxLgWinWidth } from '../../../utils/utils'
import { useAppContext } from '../../../context/appContext'
import PageInner from '../../../components/details/layout/PageInner'


type Props = {
    block1: JSX.Element,
    block2: JSX.Element,
    img:string
    
}
export default function ModulesPresentationLayout({ block1, block2, img } : Props) {
  const { winSize } = useAppContext()
  return (
   
        <PageContainer isLg={winSize.w > maxLgWinWidth}>
            <PageInner>
            <div className="space-between-main-blocks">
                {block1}
                <div className="relative">
                    {block2}
                    <div className='bg-skin-fill-muted -rotate-[15deg] w-[785px] h-[1000px] absolute top-[137px] right-[-302px]'></div>
                </div>
            </div>

            </PageInner>
        </PageContainer>

             
                      
                    
                    
      
  )
}
