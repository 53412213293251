import React from 'react'
import { useAppContext } from '../context/appContext'
import { maxLgWinWidth } from '../utils/utils'

export default function NoResource() {
  const { winSize } = useAppContext()
  return (
    <div className='w-full h-full flex justify-center items-center'>
      {winSize.w > maxLgWinWidth && (
        <p className='italic font-bold opacity-50'>Selectionner une resource</p>

      )}
    </div>
  )
}
