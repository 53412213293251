import React from 'react'
import DetailsLayout from '../../../components/details/layout'
import PageContainer from '../../../components/details/layout/PageContainer'
import PageInner from '../../../components/details/layout/PageInner'
import { useAppContext } from '../../../context/appContext'
import { maxLgWinWidth } from '../../../utils/utils'

export default function Welcome() {
  const { winSize } = useAppContext()
  return (
    <div className='h-full w-full'> 
      <DetailsLayout navigateBack='/' >
        <PageContainer isLg={winSize.w > maxLgWinWidth}>
          <PageInner>
            <div className=''>
                {/* <div className='bg-skin-fill-muted -rotate-[17deg] w-full h-[1000px] absolute top-[0] right-[-359px]'></div> */}
                <div className="pt-5 space-between-main-blocks flex flex-col items-center ">
                  <div>
                    <p className="title-level1 text-center w-full">Straight to the point</p>
                    <p className='text-level1 text-center'>Un croquis déssiné en 1998 pour comprendre teddra en un clin d'oeil</p>
                    

                  </div>
                  <div>
                    <img src="/images/home.webp" className='w-[100%] h-auto mx-auto' alt="" />
                    <p className='font-light italic'>le logo a été rajouté récemment</p>
                  </div>

                </div>
            </div>

          </PageInner>
        </PageContainer>

      </DetailsLayout>

    </div>
  )
}
