
import DetailsLayout from '../../../components/details/layout'
import { useAppContext } from '../../../context/appContext'
import { maxLgWinWidth } from '../../../utils/utils'
import PageContainer from '../../../components/details/layout/PageContainer'
import Block1 from '../../../components/details/blocks/block1'
import PageInner from '../../../components/details/layout/PageInner'
import Block2 from '../../../components/details/blocks/block2/Block2'
import Figure from '../../../components/details/blocks/figure/Figure'
import TableMultiColumns from '../../../components/details/blocks/tables/TableMultiColumns'
export default function Vesrions() {
    const { winSize } = useAppContext()
  return (
    <div className='h-full relative'>
     
        <DetailsLayout navigateBack='/bp'>
            <>
               
            <PageContainer isLg={winSize.w > maxLgWinWidth}>
                        <PageInner>
                            <div className="space-between-main-blocks">
                                <Block1
                                    title='Versions du business plan'
                                    text='Un module, un marché, un business plan'
                                    img=''
                                />
                                <div className="space-between-blocks">
                                   
                                    
                                    <div>
                                        <TableMultiColumns
                                            label='Tableau 3. Planning des versions business plan'
                                            rows={[
                                                {
                                                    columns: [
                                                        {
                                                            text: 'Module',
                                                            isTitle:true,
                                                            width: 'w-[20%]',
                                                        },
                                                        {
                                                            text: 'Marché ciblé',
                                                            isTitle:true,
                                                            width: 'w-[40%]',
                                                        },
                                                        {
                                                            text: 'Version de BP',
                                                            isTitle:true,
                                                            width: 'w-[25%]',
                                                        },
                                                        {
                                                            text: 'Date',
                                                            isTitle:true,
                                                            width: 'w-[15%]',
                                                        }
                                                    ],
                                                    isColored:false
                                                },
                                                {
                                                    columns: [
                                                        {
                                                            text: 'Dock',
                                                            icon: 'Dock',
                                                            width: 'w-[20%]',
                                                            isTitle:false
                                                        },
                                                        {
                                                            text: <div className='opacity-50'> Module satelllite</div>,
                                                            isTitle:false,
                                                            width: 'w-[40%]',

                                                        },
                                                        {
                                                            text: '',
                                                            isTitle:false,
                                                            width: 'w-[25%]',

                                                        },
                                                        {
                                                            text: '',
                                                            isTitle:false,
                                                            width: 'w-[15%]',

                                                        },
                                                    
                                                    ],
                                                    isColored:true,
                                                    center:true
                                                },
                                                {
                                                    columns: [
                                                        {
                                                            text: 'Search',
                                                            icon: 'Search',
                                                            width: 'w-[20%]',
                                                            isTitle:false
                                                        },
                                                        {
                                                            text: <div className='opacity-50'> Module satelllite</div>,
                                                            isTitle:false,
                                                            width: 'w-[40%]',

                                                        },
                                                        {
                                                            text: '',
                                                            isTitle:false,
                                                            width: 'w-[25%]',

                                                        },
                                                        {
                                                            text: '',
                                                            isTitle:false,
                                                            width: 'w-[15%]',

                                                        },
                                                    ],
                                                    isColored:false
                                                },
                                                {
                                                    columns: [
                                                        {
                                                            text: 'Files',
                                                            icon: 'Files',
                                                            width: 'w-[20%]',
                                                            isTitle:false
                                                        },
                                                        {
                                                            text: 'Les moteurs de recherche',
                                                            isTitle:false,
                                                            width: 'w-[40%]',

                                                        },
                                                        {
                                                            text: 'Business plan V1',
                                                            isTitle:false,
                                                            width: 'w-[25%]',

                                                        },
                                                        {
                                                            text: 'Sep 2024',
                                                            isTitle:false,
                                                            width: 'w-[15%]',

                                                        },
                                                    
                                                    ],
                                                    isColored:true
                                                },
                                                {
                                                    columns: [
                                                        {
                                                            text: 'Desq',
                                                            icon: 'Desq',
                                                            width: 'w-[20%]',
                                                            isTitle:false
                                                        },
                                                        {
                                                            text: 'Les browsers',
                                                            isTitle:false,
                                                            width: 'w-[40%]',

                                                        },
                                                        {
                                                            text: 'Business plan V2',
                                                            isTitle:false,
                                                            width: 'w-[25%]',

                                                        },
                                                        {
                                                            text: 'Avr 2025',
                                                            isTitle:false,
                                                            width: 'w-[15%]',

                                                        },
                                                    ],
                                                    isColored:false
                                                },
                                                {
                                                    columns: [
                                                        {
                                                            text: 'RightPage',
                                                            icon: 'RightPage',
                                                            isTitle:false,
                                                            width: 'w-[20%]'
                                                        },
                                                        {
                                                            text: <div className='opacity-50'> Module satelllite</div>,
                                                            isTitle:false,
                                                            width: 'w-[40%]',

                                                        },
                                                        {
                                                            text: '',
                                                            isTitle:false,
                                                            width: 'w-[25%]',

                                                        },
                                                        {
                                                            text: '',
                                                            isTitle:false,
                                                            width: 'w-[15%]',

                                                        },
                                                    ],
                                                    isColored:true
                                                },
                                                {
                                                    columns: [
                                                        {
                                                            text: 'Qomonpoint',
                                                            icon: 'Qomonpoint',
                                                            isTitle:false,
                                                            width: 'w-[20%]'
                                                        },
                                                        {
                                                            text: <div className='relative w-max'>
                                                                <p>Les annuaires pros + <span className='relative'>
                                                                        RSP 
                                                                        <span className='absolute top-[-5px] right-[-4px] text-[8px]'>1</span>

                                                                    </span> + <span className='relative'>
                                                                        CMS 
                                                                        <span className='absolute top-[-5px] right-[-4px] text-[8px]'>2</span>

                                                                    </span></p>
                                                            </div>,
                                                            isTitle:false,
                                                            width: 'w-[40%]',

                                                        },
                                                        {
                                                            text: 'Business plan V3',
                                                            isTitle:false,
                                                            width: 'w-[25%]',

                                                        },
                                                        {
                                                            text: 'Sep 2025',
                                                            isTitle:false,
                                                            width: 'w-[15%]',

                                                        },
                                                    ],
                                                    isColored:false
                                                },
                                                {
                                                    columns: [
                                                        {
                                                            text: 'News',
                                                            icon: 'News',
                                                            isTitle:false,
                                                            width: 'w-[20%]'
                                                        },
                                                        {
                                                            text: 'La presse professionnelle',
                                                            isTitle:false,
                                                            width: 'w-[40%]',

                                                        },
                                                        {
                                                            text: 'Business plan V4',
                                                            isTitle:false,
                                                            width: 'w-[25%]',

                                                        },
                                                        {
                                                            text: 'Jan 2026',
                                                            isTitle:false,
                                                            width: 'w-[15%]',

                                                        },
                                                    ],
                                                    isColored:true
                                                },
                                                {
                                                    columns: [
                                                        {
                                                            text: 'Calendars',
                                                            icon: 'Calendar',
                                                            isTitle:false,
                                                            width: 'w-[20%]'
                                                        },
                                                        {
                                                            text: 'La communication évènementielle',
                                                            isTitle:false,
                                                            width: 'w-[40%]',

                                                        },
                                                        {
                                                            text: 'Business plan V5',
                                                            isTitle:false,
                                                            width: 'w-[25%]',

                                                        },
                                                        {
                                                            text: 'Jun 2026',
                                                            isTitle:false,
                                                            width: 'w-[15%]',

                                                        },
                                                    ],
                                                    isColored:false
                                                },
                                                {
                                                    columns: [
                                                        {
                                                            text: 'Jobs',
                                                            icon: 'Jobs',
                                                            isTitle:false,
                                                            width: 'w-[20%]'
                                                        },
                                                        {
                                                            text: "Les sites d'emplois",
                                                            isTitle:false,
                                                            width: 'w-[40%]',

                                                        },
                                                        {
                                                            text: 'Business plan V6',
                                                            isTitle:false,
                                                            width: 'w-[25%]',

                                                        },
                                                        {
                                                            text: 'Sep 2026',
                                                            isTitle:false,
                                                            width: 'w-[15%]',

                                                        },
                                                    ],
                                                    isColored:true
                                                },
                                                {
                                                    columns: [
                                                        {
                                                            text: 'Immovables',
                                                            icon: 'Immo',
                                                            isTitle:false,
                                                            width: 'w-[20%]'
                                                        },
                                                        
                                                        {
                                                            text: "Les sites d'immobilier",
                                                            isTitle:false,
                                                            width: 'w-[40%]',

                                                        },
                                                        {
                                                            text: 'Business plan V7',
                                                            isTitle:false,
                                                            width: 'w-[25%]',

                                                        },
                                                        {
                                                            text: 'Jan 2027',
                                                            isTitle:false,
                                                            width: 'w-[15%]',

                                                        },
                                                    ],
                                                    isColored:false
                                                },
                                                {
                                                    columns: [
                                                        {
                                                            text: 'Knowledge',
                                                            icon: 'Knowledge',
                                                            isTitle:false,
                                                            width: 'w-[20%]'
                                                        },
                                                        {
                                                            text: "L'Edtech",
                                                            isTitle:false,
                                                            width: 'w-[40%]',

                                                        },
                                                        {
                                                            text: 'Business plan V8',
                                                            isTitle:false,
                                                            width: 'w-[25%]',

                                                        },
                                                        {
                                                            text: 'Jun 2027',
                                                            isTitle:false,
                                                            width: 'w-[15%]',

                                                        },
                                                    ],
                                                    isColored:true
                                                },
                                                {
                                                    columns: [
                                                        {
                                                            text: 'Market',
                                                            icon: 'Market',
                                                            isTitle:false,
                                                            width: 'w-[20%]'
                                                        },
                                                        {
                                                            text: 'Marketplace',
                                                            isTitle:false,
                                                            width: 'w-[40%]',

                                                        },
                                                        {
                                                            text: 'Business plan v9',
                                                            isTitle:false,
                                                            width: 'w-[25%]',

                                                        },
                                                        {
                                                            text: 'Sep 2027',
                                                            isTitle:false,
                                                            width: 'w-[15%]',

                                                        },
                                                    ],
                                                    isColored:false
                                                },
                                            ]}
                                    
                                            />
                                            <div className="relative mt-5 flex space-x-9">
                                                <div className='relative'>
                                                    <p className='absolute top-[-5px] left-[-4px] text-[8px]'>1</p>
                                                    <p className='italic'>Reseaux Sociaux Professionnels</p>

                                                </div>
                                                <div className='relative'>
                                                    <p className='absolute top-[-5px] left-[-4px] text-[8px]'>2</p>
                                                    <p className='italic'>Communauté Managment System</p>

                                                </div>

                                            </div>

                                    </div>
                                </div>

                            </div>
                        </PageInner>
                  

                    </PageContainer>
               
                
                   
            
            </>
        </DetailsLayout>
    </div>
  )
}
