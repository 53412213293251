import React, { useRef } from 'react'
import Icon from '../../../../components/icons/Icon'
import { CameraIcon } from '@heroicons/react/solid'
import DetailsLayout from '../../../../components/details/layout'
import { useAppContext } from '../../../../context/appContext'
import { maxLgWinWidth } from '../../../../utils/utils'
import PageContainer from '../../../../components/details/layout/PageContainer'
import Block1 from '../../../../components/details/blocks/block1'
import PageInner from '../../../../components/details/layout/PageInner'
import Block2 from '../../../../components/details/blocks/block2/Block2'
import Figure from '../../../../components/details/blocks/figure/Figure'

export default function Problem() {
    const { winSize } = useAppContext()
  return (
    <div className='h-full relative'>
     
        <DetailsLayout navigateBack='/bpv1'>
           <>
                <PageContainer isLg={winSize.w > maxLgWinWidth}>
                    <PageInner>
                        <div className="space-between-main-blocks">
                            <Block1
                                title='Probléme'
                                text="Le search aujourd'hui : les limites croissantes des moteurs traditionnels"
                                img=''
                            />
                            <div className="space-between-blocks">
                                <Block2
                                    blocks={[
                                        {
                                            title: 'Un module au coeur du succés de Teddra',
                                            text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate nisi ipsa cum odit unde voluptas, error, doloremque porro eius adipisci numquam molestias exercitationem ratione dolorem at accusamus ipsum odio dignissimos.',
                                            label: 'Teddra Files'
                                        },
                                        {
                                            title: 'Organiser le web comme un disque dur',
                                            text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate nisi ipsa cum odit unde voluptas, error, doloremque porro eius adipisci numquam molestias exercitationem ratione dolorem at accusamus ipsum odio dignissimos.',
                                            label: 'Objectif'
                                        }
                                    ]}
                                />
                                    <Figure
                                    label="Figure 1: Les ressources, les acteurs et les usages de l'Informatique Collective"
                                    img=''
                                />
                            </div>
                        
                        </div>

                    </PageInner>
                </PageContainer>
                <PageContainer isLg={winSize.w > maxLgWinWidth}>
                        <PageInner>

                            <Figure
                                label="Figure 1: Les ressources, les acteurs et les usages de l'Informatique Collective"
                                img=''
                                imgHeight='h-[600px]'
                            />
                        </PageInner>
                        
                        

                </PageContainer>
                <PageContainer isLg={winSize.w > maxLgWinWidth}>
                    <PageInner>
                            
                        <div className="space-between-blocks">
                            <Block2
                                blocks={[
                                    {
                                        title: "Une expérience Search d'un genre nouveau",
                                        text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate nisi ipsa cum odit unde voluptas, error, doloremque porro eius adipisci numquam molestias exercitationem ratione dolorem at accusamus ipsum odio dignissimos.',
                                        label: 'Performances'
                                    },
                                    
                                ]}
                            />
                            <Figure
                                label="Figure 1: Les ressources, les acteurs et les usages de l'Informatique Collective"
                                img=''
                            />
                        </div>
                        

                    </PageInner>
                </PageContainer>
                <PageContainer isLg={winSize.w > maxLgWinWidth}>
                    <PageInner>
                            
                        <div className="space-between-blocks">
                            <Block2
                                blocks={[
                                    {
                                        title: "Un module, un business plan",
                                        text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate nisi ipsa cum odit unde voluptas, error, doloremque porro eius adipisci numquam molestias exercitationem ratione dolorem at accusamus ipsum odio dignissimos.',
                                        label: 'Focus'
                                    },
                                    
                                ]}
                            />
                                <Figure
                                label="Figure 1: Les ressources, les acteurs et les usages de l'Informatique Collective"
                                img=''
                            />
                        </div>
                        

                    </PageInner>
                </PageContainer>
           
           </>
                  
                 
                 
            
        </DetailsLayout>
    </div>
  )
}
