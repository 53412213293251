import React from 'react'
import DetailsLayout from '../../../../components/details/layout'
import { useAppContext } from '../../../../context/appContext'
import { maxLgWinWidth } from '../../../../utils/utils'
import PageContainer from '../../../../components/details/layout/PageContainer'
import Block1 from '../../../../components/details/blocks/block1'
import PageInner from '../../../../components/details/layout/PageInner'
import Block2 from '../../../../components/details/blocks/block2/Block2'
import Figure from '../../../../components/details/blocks/figure/Figure'
export default function Value() {
    const { winSize } = useAppContext()
  return (
    <div className='h-full relative'>
     
        <DetailsLayout navigateBack='/bpv1'>
      
           
                <>
                    <PageContainer isLg={winSize.w > maxLgWinWidth}>
                        <PageInner>
                            <div className="space-between-main-blocks">
                                <Block1
                                    title='Proposition de valeur'
                                    text='Une précision, une pertinence et une productivité nouvelles'
                                    img=''
                                />
                                <div className="space-between-blocks">
                                    <Block2
                                        blocks={[
                                            {
                                                label: 'For users',
                                                title: 'The web. Organized.',
                                                text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate nisi ipsa cum odit unde voluptas, error, doloremque porro eius adipisci numquam molestias exercitationem ratione dolorem at accusamus ipsum odio dignissimos.'
                                            }
                                        ]}
                                    />
                                    <Figure
                                        img=''
                                        label="Figure 1: Les ressources, les acteurs et les usages de l'Informatique Collective"
                                    />
                                </div>
                            </div>
                        </PageInner>
                    </PageContainer>
                    <PageContainer isLg={winSize.w > maxLgWinWidth}>
                        <PageInner>
                              
                            <div className="space-between-blocks">
                                <Block2
                                    blocks={[
                                        {
                                            label: 'For businesses',
                                            title: 'The web. Simplified.',
                                            text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate nisi ipsa cum odit unde voluptas, error, doloremque porro eius adipisci numquam molestias exercitationem ratione dolorem at accusamus ipsum odio dignissimos.'
                                        }
                                    ]}
                                />
                                <Figure
                                    img=''
                                    label="Figure 1: Les ressources, les acteurs et les usages de l'Informatique Collective"
                                />
                            </div>
                        </PageInner>
                    </PageContainer>
                    <PageContainer isLg={winSize.w > maxLgWinWidth}>
                        <PageInner>
                              
                            <div className="space-between-blocks">
                                <Block2
                                    blocks={[
                                        {
                                            label: 'For communities',
                                            title: 'The web. Together, Stronger.',
                                            text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate nisi ipsa cum odit unde voluptas, error, doloremque porro eius adipisci numquam molestias exercitationem ratione dolorem at accusamus ipsum odio dignissimos.'
                                        }
                                    ]}
                                />
                                <Figure
                                    img=''
                                    label="Figure 1: Les ressources, les acteurs et les usages de l'Informatique Collective"
                                />
                            </div>
                        </PageInner>
                    </PageContainer>
                
                  
                 
                </>
            
        </DetailsLayout>
    </div>
  )
}
