import React, { useEffect } from 'react'
import ResourcesLocationLayout from '../../components/resources/ResourcesLayout'



const data = [
 

    {
        title: 'Vision 2024-2028',
        key: '/bp',
        
        blocks: [

       
            {
                key: '/bp/versions',
                name: 'Versions du business plan',
                icon: 'Document',
                description: 'Un module, un marché, un Business Plan'
            },
           
          
            
        ]
    }

   
]
export default function BusinessPlanLayout() {
 

  return (
    <ResourcesLocationLayout data={data} parent={{name: 'Business plan', key: '/bp'}}>
        <></>
    </ResourcesLocationLayout>
  )
}
