import React from 'react'
import Icon from '../../icons/Icon'

export default function PageInner({children} : {children:JSX.Element}) {
  return (
    <div className="relative w-full overflow-hidden">
        <div className="absolute right-[25px] top-[30px]">
            <Icon name='TeddraLogo' className='w-[25px] h-[25px]'/>
        </div>
        <div className='px-[80px] pt-[80px] relative w-full'>
            {children}
        </div>
    </div>
  )
}
