import { Drawer } from 'antd';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import TopbarIcons from '../../shared/bars/TopbarIcons';

import DetailsBar from '../../shared/bars/resourcesBar/DetailsBar';
import OuterContainer from './OuterContainer';

export default function DetrailsLayoutSm({children, navigateBack, fullWidth}: {children:JSX.Element, navigateBack:string, fullWidth?:boolean}) {
    const [open, setopen] = useState(false);
    const navigate = useNavigate()

    useEffect(() => {
      setopen(true)
    }, [])
    
  return (
    <Drawer
        visible={open}
        width={900}
        mask={false}
    >
      <>
      <div className="bar-h flex justify-end items-center px-4 bg-skin-fill-inverted text-skin-inverted">
          <TopbarIcons main={false} close={() => navigate(navigateBack)}/>

      </div>
      <div className="bar-h flex items-center px-4">
        <DetailsBar/>
      </div>
    
      {fullWidth ? (
          children
        ) : (
          <OuterContainer size='sm' >
            {children}
          </OuterContainer>
        )}
  
      </>
    </Drawer>
  )
}
