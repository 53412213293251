import React from 'react'
import Icon from '../../../icons/Icon'

type Props = {
    columns: {
        width: string,
        text: string | JSX.Element,
        isTitle: boolean,
        icon?:string,
        center?:boolean
    }[],
    center?:boolean
    isColored:boolean
}
export default function Row({ columns, isColored, center } : Props) {
  return (
    <div className={`flex py-3 px-3 ${center && 'items-center'}`} style={{background: isColored ? '#efefef' : 'inherit'}}>
        {columns.map((cl,i) => (
            <div className={`${cl.width} ${cl.center && 'flex justify-center'}`} key={i}>
                {cl.icon ? (
                    <div className="flex space-x-2 items-center">
                        <Icon className='icon-sm' name={cl.icon}/>
                        <p className={cl.isTitle ? 'font-bold' : ''}>{cl.text}</p>
                    </div>
                ) : (
                    <p className={cl.isTitle ? 'font-bold' : ''}>{cl.text}</p>

                )}
            </div>
        ))}
        
    </div>
  )
}
