import React, { useEffect, useRef } from 'react'
import DetailsLayout from '../../../components/details/layout'
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css';
import Icon from '../../../components/icons/Icon';
import { useAppContext } from '../../../context/appContext';
import { maxLgWinWidth, renderHeightLg, renderHeightSm } from '../../../utils/utils';
import TextBlock2 from '../../../components/details/blocks/block2/TextBlock2';
export default function Roadmap() {
    const { winSize, setdetailsScrollOn } = useAppContext()
    const ref = useRef<any>()

    const onScroll = () => {
        if (ref?.current) {
            
            if(ref.current.scrollTop === 0){
                setdetailsScrollOn(false)
            }else{
                setdetailsScrollOn(true)
            }
        }
      }
      useEffect(() => {
        const listInnerElement = ref?.current;
    
        if (listInnerElement) {
          listInnerElement.addEventListener("scroll", onScroll);
    
          return () => {
            listInnerElement.removeEventListener("scroll", onScroll);
          };
        }
      }, [ref]);

  return (
    <div className='w-full h-full'> 
        <DetailsLayout navigateBack='/os' fullWidth>
            <div className="space-y-8 overflow-auto pt-14" style={{height: winSize.w > maxLgWinWidth ? renderHeightLg(winSize.h) : renderHeightSm(winSize.h)}} ref={ref}>
                <VerticalTimeline lineColor='black' className='pt-0 mx-0' >
                    <VerticalTimelineElement
                    
                        className="vertical-timeline-element--work"
                        // date="2011 - present"
                        iconStyle={{background: 'white', boxShadow: 'none', width: '40px', height: '40px'}}
                        contentStyle={{boxShadow: 'none', paddingTop: '0'}}
                        position='right'
                        icon={<Icon name='RoadmapSm' className='w-[25px] h-[25px] ' />}
                    >
                    
                        <div className="relative">
                            <TextBlock2
                                label='1998'
                                title='The observation'
                                text='Lorem ipsum dolor sit amet, consectetur adipisicing elit. Qui non ea dolorum ab in molestias itaque nemo neque fugit velit illum enim doloribus, vel laudantium.'
                            
                            />

                        </div>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        // date="2011 - present"
                        iconStyle={{background: 'white', boxShadow: 'none', width: '40px', height: '40px'}}
                        contentStyle={{boxShadow: 'none', paddingTop: '0'}}
                        position='left'
                        icon={<Icon name='RoadmapSm' className='w-[25px] h-[25px]' />}
                    >
                    
                    <div className='text-right'>
                        <TextBlock2
                            label='1999'
                            title='The idea'
                            text='Lorem ipsum dolor sit amet, consectetur adipisicing elit. Qui non ea dolorum ab in molestias itaque nemo neque fugit velit illum enim doloribus, vel laudantium.'
                        
                        />

                    </div>
                       
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        // date="2011 - present"
                        iconStyle={{background: 'white', boxShadow: 'none', width: '40px', height: '40px'}}
                        contentStyle={{boxShadow: 'none', paddingTop: '0'}}
                        position='right'
                        icon={<Icon name='RoadmapSm' className='w-[25px] h-[25px]' />}
                    >
                
                        <TextBlock2
                            label='2002 - 2016'
                            title='The maturation'
                            text='Lorem ipsum dolor sit amet, consectetur adipisicing elit. Qui non ea dolorum ab in molestias itaque nemo neque fugit velit illum enim doloribus, vel laudantium.'
                        
                        />
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        // date="2011 - present"
                        iconStyle={{background: 'white', boxShadow: 'none', width: '40px', height: '40px'}}
                        contentStyle={{boxShadow: 'none', paddingTop: '0'}}
                        position='left'
                        icon={<Icon name='RoadmapSm' className='w-[25px] h-[25px]' />}
                    >
            
                     <div className="text-right">
                        <TextBlock2
                            label='2017 - 2021'
                            title='The stars aligning'
                            text='Lorem ipsum dolor sit amet, consectetur adipisicing elit. Qui non ea dolorum ab in molestias itaque nemo neque fugit velit illum enim doloribus, vel laudantium.'
                        
                        />

                     </div>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        // date="2011 - present"
                        iconStyle={{background: 'white', boxShadow: 'none', width: '40px', height: '40px'}}
                        contentStyle={{boxShadow: 'none', paddingTop: '0'}}
                        position='right'
                        icon={<Icon name='RoadmapSm' className='w-[25px] h-[25px]' />}
                    >
                    
                        
                        <TextBlock2
                            label='2021'
                            title='Meeting Hacen'
                            text='Lorem ipsum dolor sit amet, consectetur adipisicing elit. Qui non ea dolorum ab in molestias itaque nemo neque fugit velit illum enim doloribus, vel laudantium.'
                        
                        />
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        // date="2011 - present"
                        iconStyle={{background: 'white', boxShadow: 'none', width: '40px', height: '40px'}}
                        contentStyle={{boxShadow: 'none', paddingTop: '0'}}
                        position='left'
                        icon={<Icon name='RoadmapSm' className='w-[25px] h-[25px]' />}
                    >
                    
                    <div className="text-right">
                        <TextBlock2
                            label='January 2022'
                            title="Creation of the Founders' Holding"
                            text='Lorem ipsum dolor sit amet, consectetur adipisicing elit. Qui non ea dolorum ab in molestias itaque nemo neque fugit velit illum enim doloribus, vel laudantium.'
                        
                        />

                    </div>
                      
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        // date="2011 - present"
                        iconStyle={{background: 'white', boxShadow: 'none', width: '40px', height: '40px'}}
                        contentStyle={{boxShadow: 'none', paddingTop: '0'}}
                        position='right'
                        icon={<Icon name='RoadmapSm' className='w-[25px] h-[25px]' />}
                    >
                    
                      
                        <TextBlock2
                            label='2022'
                            title="Planning and Prototyping"
                            text='Lorem ipsum dolor sit amet, consectetur adipisicing elit. Qui non ea dolorum ab in molestias itaque nemo neque fugit velit illum enim doloribus, vel laudantium.'
                        
                        />
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        // date="2011 - present"
                        iconStyle={{background: 'white', boxShadow: 'none', width: '40px', height: '40px'}}
                        contentStyle={{boxShadow: 'none', paddingTop: '0'}}
                        position='left'
                        icon={<Icon name='RoadmapSm' className='w-[25px] h-[25px]' />}
                    >
                    
                    <div className="text-right">

                        <TextBlock2
                            label='T1 2023'
                            title="Testing and First Traction"
                            text='Lorem ipsum dolor sit amet, consectetur adipisicing elit. Qui non ea dolorum ab in molestias itaque nemo neque fugit velit illum enim doloribus, vel laudantium.'
                        
                        />
                    </div>
                    </VerticalTimelineElement>
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        // date="2011 - present"
                        iconStyle={{background: 'white', boxShadow: 'none', width: '40px', height: '40px'}}
                        contentStyle={{boxShadow: 'none', paddingTop: '0'}}
                        position='right'
                        icon={<Icon name='RoadmapSm' className='w-[25px] h-[25px]' />}
                    >
                    
                    
                        <TextBlock2
                            label='May 2023'
                            title="The creation of Teddra SAS"
                            text='Lorem ipsum dolor sit amet, consectetur adipisicing elit. Qui non ea dolorum ab in molestias itaque nemo neque fugit velit illum enim doloribus, vel laudantium.'
                        
                        />
                    </VerticalTimelineElement>
                
                
                </VerticalTimeline>

            </div>
        </DetailsLayout>
    </div>
  )
}
