import React from 'react'
import DetailsLayout from '../../../components/details/layout'
import PageContainer from '../../../components/details/layout/PageContainer'
import { maxLgWinWidth } from '../../../utils/utils'
import { useAppContext } from '../../../context/appContext'
import Block1 from '../../../components/details/blocks/block1'
import PageInner from '../../../components/details/layout/PageInner'
import Block2 from '../../../components/details/blocks/block2/Block2'
import TableTwoColumns from '../../../components/details/blocks/tables/TableTwoColumns'
import ModulesPresentationLayout from '../components/ModulesPresentationLayout'

export default function Search() {
  const { winSize } = useAppContext()
  return (
    <div className='h-full relative'>
      <DetailsLayout navigateBack='/os'>
            <>
            <ModulesPresentationLayout
                    
                    block1={
                        <Block1
                            title='Search'
                            text={`Le "MacOS Spotlight" dans l'univers Teddra OS`}
                            img=''
                        />
                    }
                    block2={
                        <Block2
                                    blocks={[
                                    {
                                        label: 'Description',
                                        title: 'Lorem ipsum dolor sit amet consectetur',
                                        text:' Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium iusto earum quam tempore qui voluptas veniam. Ab, distinctio quisquam quaerat labore odio facilis'
                                    }
                                    ]}
                                
                                />
                    }
                    img=''
                
                />

          
                      
                    
                    
                
                </>
        
      </DetailsLayout>

    </div>
  )
}
