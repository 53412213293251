import React, { useEffect, useState } from 'react'
import DetailsLayout from '../../../components/details/layout'
import PageContainer from '../../../components/details/layout/PageContainer'
import { maxLgWinWidth } from '../../../utils/utils'
import { useAppContext } from '../../../context/appContext'
import Block1 from '../../../components/details/blocks/block1'
import PageInner from '../../../components/details/layout/PageInner'
import Block2 from '../../../components/details/blocks/block2/Block2'
import TableTwoColumns from '../../../components/details/blocks/tables/TableTwoColumns'
import ModulesPresentationLayout from '../components/ModulesPresentationLayout'
import { useLocation } from 'react-router-dom'

export default function Files() {
  const { winSize } = useAppContext()
  const location = useLocation()
  const [backUrl, setbackUrl] = useState('/os');


  useEffect(() => {
    
    if(location.pathname.includes('os')){
        setbackUrl('/os')
    }else{
        setbackUrl('/bpv1')
    }
  }, []);
  return (
    <div className='h-full relative'>
      <DetailsLayout navigateBack={backUrl}>
            <>
                <ModulesPresentationLayout
                
                    block1={
                        <Block1
                            title='Files'
                            text='Le Collective File System'
                            img=''
                        />
                    }
                    block2={
                        <Block2
                                    blocks={[
                                    {
                                        label: 'Description',
                                        title: 'Files: le web comme un disque dur collectif',
                                        text:' Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium iusto earum quam tempore qui voluptas veniam. Ab, distinctio quisquam quaerat labore odio facilis'
                                    }
                                    ]}
                                
                                />
                    }
                    img=''
                
                />
                

                <PageContainer isLg={winSize.w > maxLgWinWidth}>
                        <PageInner>
                               <TableTwoColumns
                                    label='Tableau 1 : Presentation de Files'
                                    rows={[
                                        {
                                            columns: [
                                                {
                                                    text: 'Module',
                                                    isTitle:true
                                                },
                                                {
                                                    text: 'Description',
                                                    isTitle:true
                                                },
                                            ],
                                            isColored:false
                                        },
                                        {
                                            columns: [
                                                {
                                                    text: 'Date de sortie',
                                                    isTitle:false
                                                },
                                                {
                                                    text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem distinctio.',
                                                    isTitle:false
                                                },
                                            ],
                                            isColored:true
                                        },
                                        {
                                            columns: [
                                                {
                                                    text: 'Positionnement',
                                                    isTitle:false
                                                },
                                                {
                                                    text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem distinctio.',
                                                    isTitle:false
                                                },
                                            ],
                                            isColored:false
                                        },
                                        {
                                            columns: [
                                                {
                                                    text: 'Marché ciblé',
                                                    isTitle:false
                                                },
                                                {
                                                    text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem distinctio.',
                                                    isTitle:false
                                                },
                                            ],
                                            isColored:true
                                        },
                                        {
                                            columns: [
                                                {
                                                    text: 'Concurrence',
                                                    isTitle:false
                                                },
                                                {
                                                    text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem distinctio.',
                                                    isTitle:false
                                                },
                                            ],
                                            isColored:false
                                        },
                                        {
                                            columns: [
                                                {
                                                    text: 'Différenciation',
                                                    isTitle:false
                                                },
                                                {
                                                    text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem distinctio.',
                                                    isTitle:false
                                                },
                                            ],
                                            isColored:true
                                        },
                                        {
                                            columns: [
                                                {
                                                    text: 'Facteurs clés',
                                                    isTitle:false
                                                },
                                                {
                                                    text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem distinctio.',
                                                    isTitle:false
                                                },
                                            ],
                                            isColored:false
                                        }
                                    ]}
                               
                               />
                         
                        
                        </PageInner>
                    </PageContainer>
                      
                    
                    
                
                </>
        
      </DetailsLayout>

    </div>
  )
}
