import React, { useRef, useState } from 'react'
import { Carousel } from 'antd';
import { CameraIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import Block1 from '../../../../../../components/details/blocks/block1';

export default function PitchDeckFullScreen({fullscreen} : {fullscreen:boolean}) {
    const ref = useRef<any>()
    const [currentSlide, setcurrentSlide] = useState(1);
    const onChange = (currentSlide:number) => {
        setcurrentSlide(currentSlide +1)
      };
  return (
        <div className={`${fullscreen ? 'h-screen' :'h-[500px] '}max-h-screen w-full relative flex flex-col`}>
            <Carousel afterChange={onChange} className='w-full h-full' ref={ref}>
                <div className={`!flex space-x-16 h-full items-center w-full max-w-6xl mx-auto`}>
                    <Block1
                        text='Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus repudiandae alias, quisquam quas, optio sunt quaerat quia reiciendis eius iste ducimus'
                        title='Lorem ipsum dolor '
                        img=''
                    />
                    <div className={`w-full border border-main flex justify-center items-center bg-skin-inverted rounded h-[300px]`}>
                        <CameraIcon className='w-[50px] h-[50px] text-gray-300'/>
                    </div>
                </div>
                <div className={`!flex space-x-16 h-full items-center w-full max-w-6xl mx-auto`}>
                    <Block1
                        text='Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus repudiandae alias, quisquam quas, optio sunt quaerat quia reiciendis eius iste ducimus'
                        title='Lorem ipsum dolor2'
                        img=''
                    />
                    <div className={`w-full border border-main flex justify-center items-center bg-skin-inverted rounded h-[300px]`}>
                        <CameraIcon className='w-[50px] h-[50px] text-gray-300'/>
                    </div>
                </div>
                
        
            </Carousel>
            <div className="flex space-x-5 items-center justify-center">
                <button onClick={() => ref?.current?.prev()}>
                    <ChevronLeftIcon className='icon-sm'/>
                </button>
                <p>
                    {currentSlide}/2

                </p>
                <button onClick={() => ref?.current?.next()}>
                    <ChevronRightIcon className='icon-sm'/>
                </button>
            </div>
        </div>
  )
}
