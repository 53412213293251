import React, { useEffect, useState } from 'react'
import { useAppContext } from '../context/appContext'
import { maxLgWinWidth } from '../utils/utils';

export default function useGetnumberOfDocs(height:number, containerRef:any) {
    const { winSize, setdetailsScrollOn } = useAppContext()
    const [numberOfpages, setnumberOfpages] = useState(1);
    const [currentPage, setcurrentPage] = useState(1);

    useEffect(() => {
        const pageHeight = winSize.w > maxLgWinWidth ? winSize.w*0.6 : 1200
        setnumberOfpages(Math.round((height + 15)/(pageHeight+15)))
    }, [height, containerRef]);

    const onScroll = () => {
        if (containerRef?.current) {
            const realPageHeight = winSize.w > maxLgWinWidth ? winSize.w*0.6 : 1200
            const newCurrentPage = Math.ceil((containerRef?.current.scrollTop)/(realPageHeight+15))
            if(!newCurrentPage){
                setcurrentPage(1)
            }else{
                setcurrentPage(newCurrentPage)
            }
          
            if(containerRef?.current.scrollTop === 0){
                setdetailsScrollOn(false)
            }else{
                setdetailsScrollOn(true)
            }
        }
      }
      useEffect(() => {
        const listInnerElement = containerRef?.current;
    
        if (listInnerElement) {
          listInnerElement.addEventListener("scroll", onScroll);
    
          return () => {
            listInnerElement.removeEventListener("scroll", onScroll);
          };
        }
      }, [containerRef]);
  
     

  return (
    {
        numberOfpages,
        currentPage,
        pageHeight: winSize.w > maxLgWinWidth ? winSize.w*0.6 : 1200
    }
  )
}
