import React, { useEffect } from 'react'
import ResourcesLocationLayout from '../../components/resources/ResourcesLayout'



const data = [
    {
        title: 'Vision',
        key: '/vision',
        blocks: [
            {
                key: '/vision/opportunity',
                name: 'Opportunité',
                icon: 'Document',
                description: "Le secteur, l'état de l'art, le problème et l'opportunité ciblée"
            },
            {
                key: '/vision/teddraos',
                name: 'Teddra OS',
                icon: 'Document',
                description: 'Une nouvelle génération de contenus et de services fondés sur un environnement unifié'
            },
            {
                key: '/vision/vision',
                name: 'Vision a 3 ans',
                icon: 'Document',
                description: `Vers un "Windows" de l'Informatique Collective`
            },
        
          
        
        ]
    }
   
]

export default function VisionLayout() {

  return (
    <ResourcesLocationLayout data={data} parent={{name: 'Vision', key: '/vision'}}>
        <></>
    </ResourcesLocationLayout>
  )
}
