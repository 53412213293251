import React from 'react'

type Props = {
    children: JSX.Element,
    label: string
}
export default function TableLayout({label, children} : Props) {
  return (
    <div className="space-between-same-block">
        <p className="title-resource">{label}</p>
        <div className='border-y border-main'>
            {children}
           

        </div>
    </div>
  )
}
