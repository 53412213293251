import React, { useEffect, useRef, useState } from 'react'
import { renderHeightLg, renderHeightSm } from '../../../utils/utils'
import { useAppContext } from '../../../context/appContext'
import useGetnumberOfDocs from '../../../hooks/useGetnumberOfDocs'
import { useLocation } from 'react-router-dom'

type Props = {
    size: 'lg' | 'sm',
    children: JSX.Element
}
export default function OuterContainer({ size, children } : Props) {
    const { winSize } = useAppContext()
    const ref = useRef<any>()
    const innerRef = useRef<any>()
    const { numberOfpages, currentPage, pageHeight } = useGetnumberOfDocs(innerRef?.current?.clientHeight, ref)
    const location = useLocation()
    useEffect(() => {
      
      ref?.current?.scrollTo({top: 0, behavior: 'smooth'})
    }, [location.pathname]);
   
  return (
    <div className='pt-7 pb-[50px] w-full px-7 flex justify-center overflow-auto bg-container' style={{ height: size === 'sm' ? renderHeightSm(winSize.h) : renderHeightLg(winSize.h)}} ref={ref}>
        <div className='flex h-max w-full' ref={innerRef} onScroll={(e) => console.log(e)}>
          <div className='w-[50px]  fixed'>
            {(Array.from({length: numberOfpages} as any)).map((j,i) => (
              <div className={`${currentPage === i+1 ? 'bg-skin-fill-inverted' : 'bg-hover'} p-2 w-max -ml-2 transition-all`} key={i} onClick={() => ref?.current?.scrollTo({top: pageHeight*i+1 + (i===0 ? 0 : 15*(i+1)), behavior: 'smooth'})}>
                <div className={`w-[30px] h-[42px] bg-white border-[1px]  cursor-pointer flex justify-center items-center `} style={{borderColor: '#c9c9c9'}}>
                  <p>{i + 1}</p>
                </div>

              </div>
            ))}
              
                  

            </div>
            <div className="flex-1 flex ">
              <div className="w-[50px]">

              </div>
              <div className="flex-1 space-y-[30px]">
               {children}

              </div>
            </div>
        </div>
    </div>
  )
}
