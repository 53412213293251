
import DetailsLayout from '../../../../components/details/layout'
import { useAppContext } from '../../../../context/appContext'
import { maxLgWinWidth } from '../../../../utils/utils'
import PageContainer from '../../../../components/details/layout/PageContainer'
import Block1 from '../../../../components/details/blocks/block1'
import PageInner from '../../../../components/details/layout/PageInner'
import Block2 from '../../../../components/details/blocks/block2/Block2'
import Figure from '../../../../components/details/blocks/figure/Figure'
export default function Next() {
    const { winSize } = useAppContext()
  return (
    <div className='h-full relative'>
     
        <DetailsLayout navigateBack='/bpv1'>
            
                <>
                    <PageContainer isLg={winSize.w > maxLgWinWidth}>
                        <PageInner>
                            <div className="space-between-main-blocks">
                                <Block1
                                    title='Prochaines étapes'
                                    text='Les premiers déploiements clés '
                                    img='TeddraLogo'
                                />
                                <div className="space-between-blocks">
                                    <Block2
                                        blocks={[
                                            {
                                                label: 'Objectif',
                                                text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate nisi ipsa cum odit unde voluptas, error, doloremque porro eius adipisci numquam molestias exercitationem ratione dolorem at accusamus ipsum odio dignissimos.',
                                                title: 'Un changement de paradigme'
                                            },
                                            {
                                                label: 'Proposition de valeur',
                                                text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate nisi ipsa cum odit unde voluptas, error, doloremque porro eius adipisci numquam molestias exercitationem ratione dolorem at accusamus ipsum odio dignissimos.',
                                                title: 'Un environnement de productivité collective'
                                            },
                                        ]}
                                    />
                                    <Figure
                                        img=''
                                        label="Figure 1: Les ressources, les acteurs et les usages de l'Informatique Collective"
                                    />
                                </div>
                            </div>
                        
                        </PageInner>
                    </PageContainer>
               
                    <PageContainer isLg={winSize.w > maxLgWinWidth}>
                        <PageInner>
                            <div className="space-between-blocks">
                                <Block2
                                    blocks={[
                                        {
                                            label: 'Architecture',
                                            title:"Une construction brique par brique",
                                            text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate nisi ipsa cum odit unde voluptas, error, doloremque porro eius adipisci numquam molestias exercitationem ratione dolorem at accusamus ipsum odio dignissimos."
                                        },
                                        {
                                            label: 'Fonctionnalités',
                                            title:"Une suite de modules disruptifs",
                                            text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate nisi ipsa cum odit unde voluptas, error, doloremque porro eius adipisci numquam molestias exercitationem ratione dolorem at accusamus ipsum odio dignissimos."
                                        },
                                    ]}
                                />
                                <Figure
                                        img=''
                                        label="Figure 1: Les ressources, les acteurs et les usages de l'Informatique Collective"
                                    />
                            </div>
                        </PageInner>
                   

                    </PageContainer>
                    <PageContainer isLg={winSize.w > maxLgWinWidth}>
                        <PageInner>
                            <Figure
                                img=''
                                label="Figure 1: Les ressources, les acteurs et les usages de l'Informatique Collective"
                                imgHeight='h-[600px] '
                            />

                        </PageInner>
                     

                    </PageContainer>
                   
                </>
            
        </DetailsLayout>
    </div>
  )
}
