import React from 'react'
import DetailsLayout from '../../../components/details/layout'
import PageContainer from '../../../components/details/layout/PageContainer'
import { maxLgWinWidth } from '../../../utils/utils'
import { useAppContext } from '../../../context/appContext'
import Block1 from '../../../components/details/blocks/block1'
import PageInner from '../../../components/details/layout/PageInner'
import Block2 from '../../../components/details/blocks/block2/Block2'

export default function Why() {
  const { winSize } = useAppContext()
  return (
    <div className='h-full relative'>
      <DetailsLayout navigateBack='/'>
            <>
                <PageContainer isLg={winSize.w > maxLgWinWidth}>
                    <PageInner>
                        <>
                            <div className="space-between-main-blocks">
                                <Block1
                                    title='A propos de ce site'
                                    text='Quelques informations préliminaires '
                                    img=''
                                />
                                <div className="space-between-blocks">
                                    <Block2
                                            blocks={[
                                                {
                                                    label: 'Destination',
                                                    text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate nisi ipsa cum odit unde voluptas, error, doloremque porro eius adipisci numquam molestias exercitationem ratione dolorem at accusamus ipsum odio dignissimos.',
                                                    title: 'Lorem ipsum dolor sit amet '
                                                },
                                                {
                                                    label: 'Confidentialité',
                                                    text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate nisi ipsa cum odit unde voluptas, error, doloremque porro eius adipisci numquam molestias exercitationem ratione dolorem at accusamus ipsum odio dignissimos.',
                                                    title: 'Lorem ipsum dolor sit amet '
                                                },
                                                {
                                                    label: 'Responsabilité',
                                                    text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate nisi ipsa cum odit unde voluptas, error, doloremque porro eius adipisci numquam molestias exercitationem ratione dolorem at accusamus ipsum odio dignissimos.',
                                                    title: 'Lorem ipsum dolor sit amet '
                                                },
                                                
                                            ]}
                                        />
                                </div>

                            </div>
                        
                        </>

                    </PageInner>
                </PageContainer>
                      
                    
                    
                
                </>
        
      </DetailsLayout>

    </div>
  )
}
