import React from 'react'
import Icon from '../../../components/icons/Icon'
import { CameraIcon } from '@heroicons/react/solid'
import DetailsLayout from '../../../components/details/layout'
import { useAppContext } from '../../../context/appContext'
import { maxLgWinWidth } from '../../../utils/utils'
import PageContainer from '../../../components/details/layout/PageContainer'
import Block1 from '../../../components/details/blocks/block1'
import PageInner from '../../../components/details/layout/PageInner'
import Block2 from '../../../components/details/blocks/block2/Block2'
import Figure from '../../../components/details/blocks/figure/Figure'
import TableTwoColumns from '../../../components/details/blocks/tables/TableTwoColumns'
import TableMultiColumns from '../../../components/details/blocks/tables/TableMultiColumns'
export default function TeddraOs() {
    const { winSize } = useAppContext()
  return (
    <div className='h-full relative'>
     
        <DetailsLayout navigateBack='/vision'>
           
                <>
                    <PageContainer isLg={winSize.w > maxLgWinWidth}>
                        <PageInner>
                            <div className="space-between-main-blocks">
                                <Block1
                                    title='Teddra OS'
                                    text='Une nouvelle génération de contenus et de services fondés sur un environnement unifié'
                                    img='TeddraLogo'
                                />
                                <div className="space-between-blocks">
                                    <Block2
                                        blocks={[
                                            {
                                                label: 'Objectif',
                                                text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate nisi ipsa cum odit unde voluptas, error, doloremque porro eius adipisci numquam molestias exercitationem ratione dolorem at accusamus ipsum odio dignissimos.',
                                                title: 'Un changement de paradigme'
                                            },
                                            {
                                                label: 'Proposition de valeur',
                                                text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate nisi ipsa cum odit unde voluptas, error, doloremque porro eius adipisci numquam molestias exercitationem ratione dolorem at accusamus ipsum odio dignissimos.',
                                                title: 'Un environnement de productivité collective'
                                            },
                                        ]}
                                    />
                                    <Figure
                                        img=''
                                        label="Figure 5. Le changement de paradigme proposé par Teddra"
                                    />
                                </div>
                            </div>
                        
                        </PageInner>
                    </PageContainer>
               
                    <PageContainer isLg={winSize.w > maxLgWinWidth}>
                        <PageInner>
                            <div className="space-between-blocks">
                                <Block2
                                    blocks={[
                                        {
                                            label: 'Architecture',
                                            title:"Un environnement commun et unifié",
                                            text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate nisi ipsa cum odit unde voluptas, error, doloremque porro eius adipisci numquam molestias exercitationem ratione dolorem at accusamus ipsum odio dignissimos."
                                        }
                                    ]}
                                />
                               <Figure
                                        img=''
                                        label="Figure 5. Vue simplifier de l'environnement Teddra OS"
                                    />
                            </div>
                        </PageInner>
                   

                    </PageContainer>
                    <PageContainer isLg={winSize.w > maxLgWinWidth}>
                        <PageInner>
                            <div className="space-between-blocks">
                                <Block2
                                    blocks={[
                                        
                                        {
                                            label: 'Fonctionnalités',
                                            title:"Une suite de modules disruptifs",
                                            text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate nisi ipsa cum odit unde voluptas, error, doloremque porro eius adipisci numquam molestias exercitationem ratione dolorem at accusamus ipsum odio dignissimos."
                                        },
                                    ]}
                                />
                                <TableTwoColumns
                                    label='Tableau 1 : Les modules Teddra'
                                    rows={[
                                        {
                                            columns: [
                                                {
                                                    text: 'Module',
                                                    isTitle:true
                                                },
                                                {
                                                    text: 'Description',
                                                    isTitle:true
                                                },
                                            ],
                                            isColored:false
                                        },
                                        {
                                            columns: [
                                                {
                                                    text: 'Dock',
                                                    icon: 'Dock',
                                                    isTitle:false
                                                },
                                                {
                                                    text: '',
                                                    isTitle:false
                                                },
                                            ],
                                            isColored:true
                                        },
                                        {
                                            columns: [
                                                {
                                                    text: 'Search',
                                                    icon: 'Search',
                                                    isTitle:false
                                                },
                                                {
                                                    text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem distinctio.',
                                                    isTitle:false
                                                },
                                            ],
                                            isColored:false
                                        },
                                        {
                                            columns: [
                                                {
                                                    text: 'Files',
                                                    icon: 'Files',
                                                    isTitle:false
                                                },
                                                {
                                                    text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem distinctio.',
                                                    isTitle:false
                                                },
                                            ],
                                            isColored:true
                                        },
                                        {
                                            columns: [
                                                {
                                                    text: 'Desq',
                                                    icon: 'Desq',
                                                    isTitle:false
                                                },
                                                {
                                                    text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem distinctio.',
                                                    isTitle:false
                                                },
                                            ],
                                            isColored:false
                                        },
                                        {
                                            columns: [
                                                {
                                                    text: 'RightPage',
                                                    icon: 'RightPage',
                                                    isTitle:false
                                                },
                                                {
                                                    text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem distinctio.',
                                                    isTitle:false
                                                },
                                            ],
                                            isColored:true
                                        },
                                        {
                                            columns: [
                                                {
                                                    text: 'Qomonpoint',
                                                    icon: 'Qomonpoint',
                                                    isTitle:false
                                                },
                                                {
                                                    text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem distinctio.',
                                                    isTitle:false
                                                },
                                            ],
                                            isColored:false
                                        },
                                        {
                                            columns: [
                                                {
                                                    text: 'News',
                                                    icon: 'News',
                                                    isTitle:false
                                                },
                                                {
                                                    text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem distinctio.',
                                                    isTitle:false
                                                },
                                            ],
                                            isColored:true
                                        },
                                        {
                                            columns: [
                                                {
                                                    text: 'Calendars',
                                                    icon: 'Calendar',
                                                    isTitle:false
                                                },
                                                {
                                                    text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem distinctio.',
                                                    isTitle:false
                                                },
                                            ],
                                            isColored:false
                                        },
                                        {
                                            columns: [
                                                {
                                                    text: 'Jobs',
                                                    icon: 'Jobs',
                                                    isTitle:false
                                                },
                                                {
                                                    text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem distinctio.',
                                                    isTitle:false
                                                },
                                            ],
                                            isColored:true
                                        },
                                        {
                                            columns: [
                                                {
                                                    text: 'Immovables',
                                                    icon: 'Immo',
                                                    isTitle:false
                                                },
                                                {
                                                    text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem distinctio.',
                                                    isTitle:false
                                                },
                                            ],
                                            isColored:false
                                        },
                                        {
                                            columns: [
                                                {
                                                    text: 'Knowledge',
                                                    icon: 'Knowledge',
                                                    isTitle:false
                                                },
                                                {
                                                    text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem distinctio.',
                                                    isTitle:false
                                                },
                                            ],
                                            isColored:true
                                        },
                                        {
                                            columns: [
                                                {
                                                    text: 'Market',
                                                    icon: 'Market',
                                                    isTitle:false
                                                },
                                                {
                                                    text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem distinctio.',
                                                    isTitle:false
                                                },
                                            ],
                                            isColored:false
                                        },
                                    ]}
                               
                               />
                            </div>
                        </PageInner>
                   

                    </PageContainer>
                    <PageContainer isLg={winSize.w > maxLgWinWidth}>
                        <PageInner>
                            <div className="space-between-blocks">
                                <Block2
                                    blocks={[
                                        {
                                            label: "Interface utilisateur",
                                            title: 'Un "look & feel" à la MacOS',
                                            text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate nisi ipsa cum odit unde voluptas, error, doloremque porro eius adipisci numquam molestias exercitationem ratione dolorem at accusamus ipsum odio dignissimos."
                                        }
                                    ]}
                                />
                                <Figure
                                    label="Figure 6. L'environnement graphique de Teddra OS"
                                    img=''
                                />

                            </div>
                        </PageInner>
                       
                        

                    </PageContainer>
                    <PageContainer isLg={winSize.w > maxLgWinWidth}>
                        <PageInner>
                            <div className="space-between-blocks">
                                <Block2
                                    blocks={[
                                        {
                                            label: "fonctionnement génerale",
                                            title: 'Une approche organisée et intuitive du monde réel',
                                            text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate nisi ipsa cum odit unde voluptas, error, doloremque porro eius adipisci numquam molestias exercitationem ratione dolorem at accusamus ipsum odio dignissimos."
                                        }
                                    ]}
                                />
                                <Figure
                                    label="Figure 7. La navigation dans Teddra OS"
                                    img=''
                                />

                            </div>
                        </PageInner>
                       
                        

                    </PageContainer>
                    <PageContainer isLg={winSize.w > maxLgWinWidth}>
                         <PageInner>
                            <div className="space-between-blocks">
                                <Block2
                                    blocks={[
                                        {
                                            label: "Usages",
                                            title: 'Un usage universel et quotidien',
                                            text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate nisi ipsa cum odit unde voluptas, error, doloremque porro eius adipisci numquam molestias exercitationem ratione dolorem at accusamus ipsum odio dignissimos."
                                        }
                                    ]}
                                />
                              
                                <Figure
                                    label="Figure 8. Les profils d'utilisateurs de Teddra OS"
                                    img=''
                                />

                            </div>
                        </PageInner>
                       

                    </PageContainer>
                    <PageContainer isLg={winSize.w > maxLgWinWidth}>
                        <PageInner>
                            <div className="space-between-blocks">
                                <Block2
                                    blocks={[
                                        {
                                            label: "Technologie",
                                            title: 'Une plateforme basée sur le cloud',
                                            text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate nisi ipsa cum odit unde voluptas, error, doloremque porro eius adipisci numquam molestias exercitationem ratione dolorem at accusamus ipsum odio dignissimos."
                                        },
                                        {
                                            label: "Propriété intellectuelle",
                                            title: 'Une technologie détenue par Teddra Inc.',
                                            text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate nisi ipsa cum odit unde voluptas, error, doloremque porro eius adipisci numquam molestias exercitationem ratione dolorem at accusamus ipsum odio dignissimos."
                                        },
                                    ]}
                                />
                                <Figure
                                    label="Figure 9. Les principales briques technologiques de Teddra OS"
                                    img=''
                                />

                            </div>
                        </PageInner>
                        

                    </PageContainer>
                    <PageContainer isLg={winSize.w > maxLgWinWidth}>
                        <PageInner>
                            <div className="space-between-blocks">
                                <Block2
                                    blocks={[
                                        {
                                            label: "Roadmap",
                                            title: 'Le  plan R&D sur 3 ans',
                                            text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate nisi ipsa cum odit unde voluptas, error, doloremque porro eius adipisci numquam molestias exercitationem ratione dolorem at accusamus ipsum odio dignissimos."
                                        }
                                    ]}
                                />
                                 <TableMultiColumns
                                    label='Tableau 2 : Les modules Teddra'
                                    rows={[
                                        {
                                            columns: [
                                                {
                                                    text: 'Module',
                                                    isTitle:true,
                                                    width: 'w-[25%]',
                                                },
                                                {
                                                    text: 'Teddra OS 2025',
                                                    isTitle:true,
                                                    width: 'w-[25%]',
                                                    center:true
                                                },
                                                {
                                                    text: 'Teddra OS 2026',
                                                    isTitle:true,
                                                    width: 'w-[25%]',
                                                    center:true
                                                },
                                                {
                                                    text: 'Teddra OS 2027',
                                                    isTitle:true,
                                                    width: 'w-[25%]',
                                                    center:true
                                                },
                                            ],
                                            isColored:false
                                        },
                                        {
                                            columns: [
                                                {
                                                    text: 'Dock',
                                                    icon: 'Dock',
                                                    width: 'w-[25%]',
                                                    isTitle:false
                                                },
                                                {
                                                    text: <div className='flex space-x-2 items-center'>
                                                        <p>Q1</p>
                                                        <p className='opacity-30'>Q2</p>
                                                        <p className='opacity-30'>Q3</p>
                                                        <p className='opacity-30'>Q4</p>
                                                    </div>,
                                                    center:true,
                                                    isTitle:false,
                                                    width: 'w-[25%]',

                                                },
                                                {
                                                    text: '',
                                                    center:true,
                                                    isTitle:false,
                                                    width: 'w-[25%]',

                                                },
                                                {
                                                    text: '',
                                                    center:true,
                                                    isTitle:false,
                                                    width: 'w-[25%]',

                                                },
                                            ],
                                            isColored:true,
                                            center:true
                                        },
                                        {
                                            columns: [
                                                {
                                                    text: 'Search',
                                                    icon: 'Search',
                                                    width: 'w-[25%]',
                                                    isTitle:false
                                                },
                                              {
                                                    text: <div className='flex space-x-2 items-center'>
                                                        <p>Q1</p>
                                                        <p className='opacity-30'>Q2</p>
                                                        <p className='opacity-30'>Q3</p>
                                                        <p className='opacity-30'>Q4</p>
                                                    </div>,
                                                    center:true,
                                                    isTitle:false,
                                                    width: 'w-[25%]',

                                                },
                                                {
                                                    text: '',
                                                    center:true,
                                                    isTitle:false,
                                                    width: 'w-[25%]',

                                                },
                                                {
                                                    text: '',
                                                    center:true,
                                                    isTitle:false,
                                                    width: 'w-[25%]',

                                                },
                                            ],
                                            isColored:false
                                        },
                                        {
                                            columns: [
                                                {
                                                    text: 'Files',
                                                    icon: 'Files',
                                                    width: 'w-[25%]',
                                                    isTitle:false
                                                },
                                                {
                                                    text: <div className='flex space-x-2 items-center'>
                                                        <p>Q1</p>
                                                        <p className='opacity-30'>Q2</p>
                                                        <p className='opacity-30'>Q3</p>
                                                        <p className='opacity-30'>Q4</p>
                                                    </div>,
                                                    center:true,
                                                    isTitle:false,
                                                    width: 'w-[25%]',

                                                },
                                                {
                                                    text: '',
                                                    center:true,
                                                    isTitle:false,
                                                    width: 'w-[25%]',

                                                },
                                                {
                                                    text: '',
                                                    center:true,
                                                    isTitle:false,
                                                    width: 'w-[25%]',

                                                },
                                            ],
                                            isColored:true
                                        },
                                        {
                                            columns: [
                                                {
                                                    text: 'Desq',
                                                    icon: 'Desq',
                                                    width: 'w-[25%]',
                                                    isTitle:false
                                                },
                                                {
                                                    text: <div className='flex space-x-2 items-center'>
                                                        <p className='opacity-30'>Q1</p>
                                                        <p>Q2</p>
                                                        <p className='opacity-30'>Q3</p>
                                                        <p className='opacity-30'>Q4</p>
                                                    </div>,
                                                    center:true,
                                                    isTitle:false,
                                                    width: 'w-[25%]',

                                                },
                                                {
                                                    text: '',
                                                    center:true,
                                                    isTitle:false,
                                                    width: 'w-[25%]',

                                                },
                                                {
                                                    text: '',
                                                    center:true,
                                                    isTitle:false,
                                                    width: 'w-[25%]',

                                                },
                                            ],
                                            isColored:false
                                        },
                                        {
                                            columns: [
                                                {
                                                    text: 'RightPage',
                                                    icon: 'RightPage',
                                                    isTitle:false,
                                                    width: 'w-[25%]'
                                                },
                                                {
                                                    text: <div className='flex space-x-2 items-center'>
                                                        <p className='opacity-30'>Q1</p>
                                                        <p>Q2</p>
                                                        <p className='opacity-30'>Q3</p>
                                                        <p className='opacity-30'>Q4</p>
                                                    </div>,
                                                    center:true,
                                                    isTitle:false,
                                                    width: 'w-[25%]',

                                                },
                                                {
                                                    text: '',
                                                    center:true,
                                                    isTitle:false,
                                                    width: 'w-[25%]',

                                                },
                                                {
                                                    text: '',
                                                    center:true,
                                                    isTitle:false,
                                                    width: 'w-[25%]',

                                                },
                                            ],
                                            isColored:true
                                        },
                                        {
                                            columns: [
                                                {
                                                    text: 'Qomonpoint',
                                                    icon: 'Qomonpoint',
                                                    isTitle:false,
                                                    width: 'w-[25%]'
                                                },
                                                {
                                                    text: '',
                                                    center:true,
                                                    isTitle:false,
                                                    width: 'w-[25%]',

                                                },
                                                {
                                                    text: <div className='flex space-x-2 items-center'>
                                                        <p>Q1</p>
                                                        <p className='opacity-30'>Q2</p>
                                                        <p className='opacity-30'>Q3</p>
                                                        <p className='opacity-30'>Q4</p>
                                                    </div>,
                                                    center:true,
                                                    isTitle:false,
                                                    width: 'w-[25%]',

                                                },
                                                {
                                                    text: '',
                                                    center:true,
                                                    isTitle:false,
                                                    width: 'w-[25%]',

                                                },
                                            ],
                                            isColored:false
                                        },
                                        {
                                            columns: [
                                                {
                                                    text: 'News',
                                                    icon: 'News',
                                                    isTitle:false,
                                                    width: 'w-[25%]'
                                                },
                                                {
                                                    text: '',
                                                    center:true,
                                                    isTitle:false,
                                                    width: 'w-[25%]',

                                                },
                                                {
                                                    text: <div className='flex space-x-2 items-center'>
                                                        <p className='opacity-30'>Q1</p>
                                                        <p>Q2</p>
                                                        <p className='opacity-30'>Q3</p>
                                                        <p className='opacity-30'>Q4</p>
                                                    </div>,
                                                    center:true,
                                                    isTitle:false,
                                                    width: 'w-[25%]',

                                                },
                                                {
                                                    text: '',
                                                    center:true,
                                                    isTitle:false,
                                                    width: 'w-[25%]',

                                                },
                                            ],
                                            isColored:true
                                        },
                                        {
                                            columns: [
                                                {
                                                    text: 'Calendars',
                                                    icon: 'Calendar',
                                                    isTitle:false,
                                                    width: 'w-[25%]'
                                                },
                                                {
                                                    text: '',
                                                    center:true,
                                                    isTitle:false,
                                                    width: 'w-[25%]',

                                                },
                                                {
                                                    text: <div className='flex space-x-2 items-center'>
                                                        <p className='opacity-30'>Q1</p>
                                                        <p className='opacity-30'>Q2</p>
                                                        <p className='opacity-30'>Q3</p>
                                                        <p>Q4</p>
                                                    </div>,
                                                    center:true,
                                                    isTitle:false,
                                                    width: 'w-[25%]',

                                                },
                                                {
                                                    text: '',
                                                    center:true,
                                                    isTitle:false,
                                                    width: 'w-[25%]',

                                                },
                                            ],
                                            isColored:false
                                        },
                                        {
                                            columns: [
                                                {
                                                    text: 'Jobs',
                                                    icon: 'Jobs',
                                                    isTitle:false,
                                                    width: 'w-[25%]'
                                                },
                                                {
                                                    text: '',
                                                    isTitle:false,
                                                    width: 'w-[25%]'
                                                },
                                                {
                                                    text: '',
                                                    center:true,
                                                    isTitle:false,
                                                    width: 'w-[25%]',

                                                },
                                                {
                                                    text: '',
                                                    center:true,
                                                    isTitle:false,
                                                    width: 'w-[25%]',
                                                    
                                                },
                                                {
                                                    text: <div className='flex space-x-2 items-center'>
                                                        <p>Q1</p>
                                                        <p className='opacity-30'>Q2</p>
                                                        <p className='opacity-30'>Q3</p>
                                                        <p className='opacity-30'>Q4</p>
                                                    </div>,
                                                    center:true,
                                                    isTitle:false,
                                                    width: 'w-[25%]',
    
                                                },
                                            ],
                                            isColored:true
                                        },
                                        {
                                            columns: [
                                                {
                                                    text: 'Immovables',
                                                    icon: 'Immo',
                                                    isTitle:false,
                                                    width: 'w-[25%]'
                                                },
                                                {
                                                    text: '',
                                                    isTitle:false,
                                                    width: 'w-[25%]'
                                                },
                                                {
                                                    text: '',
                                                    center:true,
                                                    isTitle:false,
                                                    width: 'w-[25%]',

                                                },
                                                {
                                                    text: '',
                                                    center:true,
                                                    isTitle:false,
                                                    width: 'w-[25%]',
                                                    
                                                },
                                                {
                                                    text: <div className='flex space-x-2 items-center'>
                                                        <p className='opacity-30'>Q1</p>
                                                        <p>Q2</p>
                                                        <p className='opacity-30'>Q3</p>
                                                        <p className='opacity-30'>Q4</p>
                                                    </div>,
                                                    center:true,
                                                    isTitle:false,
                                                    width: 'w-[25%]',
    
                                                },
                                            ],
                                            isColored:false
                                        },
                                        {
                                            columns: [
                                                {
                                                    text: 'Knowledge',
                                                    icon: 'Knowledge',
                                                    isTitle:false,
                                                    width: 'w-[25%]'
                                                },
                                                {
                                                    text: '',
                                                    isTitle:false,
                                                    width: 'w-[25%]'
                                                },
                                                {
                                                    text: '',
                                                    center:true,
                                                    isTitle:false,
                                                    width: 'w-[25%]',

                                                },
                                                {
                                                    text: '',
                                                    center:true,
                                                    isTitle:false,
                                                    width: 'w-[25%]',
                                                    
                                                },
                                                {
                                                    text: <div className='flex space-x-2 items-center'>
                                                        <p className='opacity-30'>Q1</p>
                                                        <p className='opacity-30'>Q2</p>
                                                        <p>Q3</p>
                                                        <p className='opacity-30'>Q4</p>
                                                    </div>,
                                                    center:true,
                                                    isTitle:false,
                                                    width: 'w-[25%]',
    
                                                },
                                            ],
                                            isColored:true
                                        },
                                        {
                                            columns: [
                                                {
                                                    text: 'Market',
                                                    icon: 'Market',
                                                    isTitle:false,
                                                    width: 'w-[25%]'
                                                },
                                                {
                                                    text: '',
                                                    isTitle:false,
                                                    width: 'w-[25%]'
                                                },
                                                {
                                                    text: '',
                                                    center:true,
                                                    isTitle:false,
                                                    width: 'w-[25%]',

                                                },
                                                {
                                                    text: '',
                                                    center:true,
                                                    isTitle:false,
                                                    width: 'w-[25%]',
                                                    
                                                },
                                                {
                                                    text: <div className='flex space-x-2 items-center'>
                                                        <p className='opacity-30'>Q1</p>
                                                        <p className='opacity-30'>Q2</p>
                                                        <p className='opacity-30'>Q3</p>
                                                        <p>Q4</p>
                                                    </div>,
                                                    center:true,
                                                    isTitle:false,
                                                    width: 'w-[25%]',
    
                                                },
                                            ],
                                            isColored:false
                                        },
                                    ]}
                               
                               />

                            </div>
                        </PageInner>
                  

                    </PageContainer>
                 
                </>
            
        </DetailsLayout>
    </div>
  )
}
