import React from 'react'
import ResourcesLocationLayout from '../../components/resources/ResourcesLayout'
import Resources from '../../components/resources'


const data = [
    {
        title: 'Solutions',
        key: '/solutions',
        blocks: [
            {
                key: '/solutions/pro',
                name: 'Teddra for Pro',
                icon: '',
                description: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis recusandae, beatae placeat sit harum delectus atque enim molestias ullam illum repellendus sequi!'
            },
            {
                key: '/solutions/business',
                name: 'Teddra for Business',
                icon: '',
                description: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis recusandae, beatae placeat sit harum delectus atque enim molestias ullam illum repellendus sequi!'
            },
            {
                key: '/solutions/association',
                name: 'Teddra for Association',
                icon: '',
                description: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis recusandae, beatae placeat sit harum delectus atque enim molestias ullam illum repellendus sequi!'
            },
            {
                key: '/solutions/chambers-of-commerce',
                name: 'Teddra for Chambers of commerce',
                icon: '',
                description: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis recusandae, beatae placeat sit harum delectus atque enim molestias ullam illum repellendus sequi!'
            },
            {
                key: '/solutions/higher-education',
                name: 'Teddra for Higher education',
                icon: '',
                description: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis recusandae, beatae placeat sit harum delectus atque enim molestias ullam illum repellendus sequi!'
            },
            {
                key: '/solutions/ecommerce',
                name: 'Teddra for E-commerce',
                icon: '',
                description: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis recusandae, beatae placeat sit harum delectus atque enim molestias ullam illum repellendus sequi!'
            },
            {
                key: '/solutions/brand',
                name: 'Teddra for Brand',
                icon: '',
                description: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis recusandae, beatae placeat sit harum delectus atque enim molestias ullam illum repellendus sequi!'
            },
        ]
    }
   
]
export default function SolutionsLayout() {
  return (
    <ResourcesLocationLayout data={data} parent={{name: 'Solutions', key: '/solutions'}}>
        <></>
    </ResourcesLocationLayout>
  )
}
