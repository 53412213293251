import React from 'react'
import Resource from './Resource'
import { useLocation, useNavigate } from 'react-router-dom'



export default function Resources({data} : {data: {title:string,key:string, blocks:{key:string,name:string,icon:string, description:string, disabled?:boolean}[]}[]}) {
    const location = useLocation()
    const navigate = useNavigate()
    
  return (
    <div className='space-y-10'>
        {data.map((block, i) => (
            <div className='space-between-lines' key={i}>
                {data.length > 1 && <p className="text-group">{block.title}</p>}
                <div className="flex flex-wrap gap-x-2 -ml-3">
                    {block.blocks.map(el => (
                        <Resource key={el.key} id={el.key} name={el.name} description={el.description} icon={el.icon} action={(key) => navigate(key)} active={location.pathname === el.key} disabled={el.disabled}/>
                    ))}

                </div>
            </div>
        ))}
    </div>
  )
}
