import React from 'react'
import ResourcesLocationLayout from '../../components/resources/ResourcesLayout'


const data = [
  {
      title: 'Home',
      key: '/home',
      blocks: [
          {
              key: '/',
              name: "Straight to the point",
              icon: '',
              description: "Un croquis déssiné en 1998 pour comprendre teddra en un clin d'oeil"
          },
          {
              key: '/home/about',
              name: "A propos de ce site",
              icon: '',
              description: 'Quelques informations préliminaires '
          }
         
          // {
          //     key: '/deck/demo',
          //     name: 'Demo',
          //     icon: '',
          //     description: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis recusandae, beatae placeat sit harum delectus atque enim molestias ullam illum repellendus sequi!'
          // },
          // {
          //     key: '/deck/slides',
          //     name: '13 slides',
          //     icon: '',
          //     description: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis recusandae, beatae placeat sit harum delectus atque enim molestias ullam illum repellendus sequi!'
          // }
      ]
  }
 
]
export default function HomeLayout() {
  return (
    <ResourcesLocationLayout data={data}>
      <></>
    </ResourcesLocationLayout>
  )
}
