import React, { useEffect } from 'react'
import ResourcesLocationLayout from '../../components/resources/ResourcesLayout'



const data = [
    {
        title: "Vue d'ensemble",
        key: '/ecosystem',
        blocks: [
            {
                key: '/ecosystem/teddra-as-platform',
                name: 'Un modèle de platforme ',
                icon: '',
                description: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis recusandae, beatae placeat sit harum delectus atque enim molestias ullam illum repellendus sequi!',
                
            },
            {
                key: '/ecosystem/teddra-ventures',
                name: 'Teddra ventures ',
                icon: '',
                description: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis recusandae, beatae placeat sit harum delectus atque enim molestias ullam illum repellendus sequi!',
                
            },
        ]
    },
    {
        title: 'Les partenaires industriels',
        key: '/ecosystem',
        blocks: [
            {
                key: '/ecosystem/infra-providers',
                name: "Les fornisseures d'infrastructures",
                icon: '',
                description: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis recusandae, beatae placeat sit harum delectus atque enim molestias ullam illum repellendus sequi!'
            },
            {
                key: '/ecosystem/data-providers',
                name: 'Les fornisseures de data',
                icon: '',
                description: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis recusandae, beatae placeat sit harum delectus atque enim molestias ullam illum repellendus sequi!'
            }
           
        ]
    },
    {
        title: 'Les partenaires commerciaux',
        key: '/ecosystem',
        blocks: [
            {
                key: '/ecosystem/publishers',
                name: 'Les groupes de presses',
                icon: '',
                description: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis recusandae, beatae placeat sit harum delectus atque enim molestias ullam illum repellendus sequi!'
            },
            {
                key: '/ecosystem/web-agencies',
                name: 'Les agences web',
                icon: '',
                description: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis recusandae, beatae placeat sit harum delectus atque enim molestias ullam illum repellendus sequi!'
            },
            {
                key: '/ecosystem/developers',
                name: 'Les developpeurs',
                icon: '',
                description: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Reiciendis recusandae, beatae placeat sit harum delectus atque enim molestias ullam illum repellendus sequi!'
            }
           
        ]
    }
   
]

export default function EcosystemLayout() {

  return (
    <ResourcesLocationLayout data={data} parent={{name: 'Ecosystem', key: '/ecosystem'}}>
        <></>
    </ResourcesLocationLayout>
  )
}
