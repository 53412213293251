import React from 'react'
import Row from './Row'
import TableLayout from './TableLayout'

type Props = {
    label: string,
    rows : 
    {
        columns: {
            text: string,
            isTitle: boolean,
            icon?:string
        }[],
        isColored: boolean,

    }[]
}
export default function TableTwoColumns({label, rows} : Props) {
  return (
    <TableLayout label={label}>
        <>
            {rows.map((row,i) => (
                <Row
                    columns={row.columns.map((cl,j) => ({...cl, width: j === 0 ? 'w-[130px]' : 'flex-1'}))}
                    isColored={row.isColored}
                />
            ))}
        
        </>

    </TableLayout>
           

     
  )
}
